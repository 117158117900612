import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonConfigurationService } from '@ngxhq/config';
import { ThemeHqCommonUiSupportModule, ThemeHqCustomComponentModule } from '@ngxhq/theme-ngxhq';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { AppConfiguration } from './config/app-config.model';
import { sharedDirectives } from './directives';
import { sharedComponents } from './components';

@NgModule({
    imports: [
        CommonModule,
        ThemeHqCommonUiSupportModule,
        ThemeHqCustomComponentModule,
        ProgressSpinnerModule,
    ],
    exports: [
        ThemeHqCommonUiSupportModule,
        ThemeHqCustomComponentModule,
        FormsModule,
        ReactiveFormsModule,
        ProgressSpinnerModule,

        // bulk export shared components
        ...sharedComponents,

        // bulk export all shared directives
        ...sharedDirectives,
    ],
    declarations: [
        // bulk declare shared components
        ...sharedComponents,

        // bulk declare shared directives
        ...sharedDirectives,
    ],
    providers: [
        {
            provide: AppConfiguration,
            useFactory: (commonConfigurationService: CommonConfigurationService<AppConfiguration>) => commonConfigurationService.getConfiguration(AppConfiguration),
            deps: [CommonConfigurationService]
        }
    ],
})
export class SharedModule {
}
