import { Component } from '@angular/core';
import { ComponentBase, ComponentFeatures, InheritsBaseLifecycleHooks } from '@ngxhq/common-ui';

@Component({
    styleUrls: ['./home.component.scss'],
    templateUrl: './home.component.html'
})
@ComponentFeatures([
    InheritsBaseLifecycleHooks()
])
export class HomeComponent extends ComponentBase {
}
