import { Params } from '@angular/router';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EditMode } from '../../models';
import { CrudFormState } from '../reducers/crud-form.reducers';
import { getRouterData } from './router.selectors';

export const getCrudFormState = createFeatureSelector<CrudFormState>('crudForm');

export const getTactiqueForm = createSelector(
    getCrudFormState,
    (state: CrudFormState) => state && state.tactiqueForm || null,
);

export const getExecutionForm = createSelector(
    getCrudFormState,
    (state: CrudFormState) => state && state.executionForm || null,
);

export const getRouterDataEditMode = createSelector(
    getRouterData,
    (data: Params) =>
        data && data.editMode as EditMode || EditMode.EDITION
);

export const isFormInCreation = createSelector(
    getRouterDataEditMode,
    (editMode: EditMode) =>
        editMode && editMode === EditMode.CREATION || false
);
