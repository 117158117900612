import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';

import { RouterStateSerializer } from '@ngrx/router-store';

import { RouterStateUrl } from '../../models';

/**
 * This serializer takes a router state from `@ngrx/router-store` and map it to a `RouterStateUrl`.
 * Used internally by `@ngrx/router-store` service.
 *
 * We can then map router-store objects to a convenient and custom state containing: `url`, `params` and `queryParms`.
 */
@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
    /**
     * Converts a `@ngrx/router-store state` to custom `RouterStateUrl`
     */
    serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        let route = routerState.root;
        let params = {};
        let data = {};

        while (route.firstChild) {
            route = route.firstChild;
            params = { ...params, ...route.params };
            data = { ...data, ...route.data };
        }

        const { url, root: { queryParams } } = routerState;

        return { url, params, queryParams, data };
    }
}
