import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { appNotificationsReducer, AppNotificationsState } from './app-notifications.reducer';
import { userReducer, UserState } from './user.reducer';
import { campagnesReducer, CampagneState } from './campagne.reducer';
import { tactiquesReducer, TactiqueState } from './tactique.reducer';
import { modeleSolicitationsReducer, ModeleSolicitationState } from './modele-solicitation.reducer';
import { crudFormReducer, CrudFormState } from './crud-form.reducers';
import { RouterStateUrl } from '../../models';
import { executionsReducer, ExecutionState } from './execution.reducer';

export interface CoreState {
    router: RouterReducerState<RouterStateUrl>;
    user: UserState;
    appNotifications: AppNotificationsState;
    campagnes: CampagneState;
    tactiques: TactiqueState;
    executions: ExecutionState;
    modeleSolicitations: ModeleSolicitationState;
    crudForm: CrudFormState;
}

export const coreReducers: ActionReducerMap<CoreState> = {
    router: routerReducer,
    user: userReducer,
    appNotifications: appNotificationsReducer,
    campagnes: campagnesReducer,
    tactiques: tactiquesReducer,
    executions: executionsReducer,
    modeleSolicitations: modeleSolicitationsReducer,
    crudForm: crudFormReducer
};

export const getCoreState = createFeatureSelector<CoreState>('core');
