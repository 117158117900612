export class Niveau {

    constructor(
        public niveau: string,
    ) { }

    static fromJSON(json: any): Niveau {
        const object: Niveau = Object.create(Niveau.prototype);
        Object.assign(object, json);
        return object;
    }

    toString(): string {
        return `Niveau: ${JSON.stringify(this)}`;
    }
}
