import { NavigationExtras } from '@angular/router';

import { createAction, props, union } from '@ngrx/store';

export interface RouterActionPayload {
    path: string[];
    query?: object;
    extras?: NavigationExtras;
}

export enum RouterActionsTypes {
    GO = '[Router] Go',
    GO_RELOAD = '[Router] Go Reload',
    BACK = '[Router] Back',
    FORWARD = '[Router] Forward',
}

/**
 * Router action - Triggers a navigation to a given route
 */
export const go = createAction(
    RouterActionsTypes.GO,
    props<{ payload: RouterActionPayload }>(),
);

/**
 * Router action - Triggers a navigation to a given route then hard reload the page.
 *
 * Used to clear state, for example after having landed on an error page.
 */
export const goReload = createAction(
    RouterActionsTypes.GO_RELOAD,
    props<{ payload: RouterActionPayload }>(),
);

/**
 * Router action - Triggers a navigation back using angular router history
 */
export const back = createAction(
    RouterActionsTypes.BACK,
);

/**
 * Router action - Triggers a navigation forward using angular router history
 */
export const forward = createAction(
    RouterActionsTypes.FORWARD,
);

/**
 * NGRX requires that a union type being exported to be used as reference type
 * for methods (like effects) using these actions.
 *
 * This is to ensure proper typing.
 */
const routerActions = union({
    go,
    goReload,
    back,
    forward,
});

export type RouterActions = typeof routerActions;
