import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap } from 'rxjs/operators';

import { go } from '../../../core/store/actions';
import { addOneAppNotification } from '../../../core/store/actions/app-notifications.actions';
import { Campagne, ErrorNotification, StatutsCampagne, SuccessNotification } from '../../models';
import { CampagneService } from '../../services/campagne.service';
import {
    CampagneActions, createCampagne, createCampagneFail, createCampagneSuccess, deleteCampagne, deleteCampagneFail,
    deleteCampagneSuccess, duplicateCampagne, duplicateCampagneFail, duplicateCampagneSuccess, loadCampagnes,
    loadCampagnesFail, loadCampagnesSuccess, updateCampagne, updateCampagneFail, updateCampagneSuccess
} from '../actions/campagne.actions';

@Injectable()
export class CampagneEffects {

    loadCampagnes$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(loadCampagnes),
                switchMap(() => this.campagneService.getCampagnes()
                    .pipe(
                        map(campagnes =>
                            campagnes.map(campagne => ({
                                ...campagne,
                                dateDebut: new DatePipe('fr').transform(campagne.dateDebut, 'yyyy-MM-dd'),
                                dateFin: new DatePipe('fr').transform(campagne.dateFin, 'yyyy-MM-dd'),
                                statutLibelle: StatutsCampagne[`${campagne.statut}`],
                                nbTactiques: (campagne.idsTactiques) ? campagne.idsTactiques.length : 0
                            }))
                        ),
                        map(campagnes => loadCampagnesSuccess({ campagnes })),
                        catchError((error: any) => of(loadCampagnesFail({ error })))
                    ),
                ),
            );
    });


    createCampagne$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(createCampagne),
                concatMap(action =>
                    this.campagneService.createCampagne(action.campagne)
                        .pipe(
                            map(campagne => ({
                                ...campagne,
                                dateDebut: new DatePipe('fr').transform(campagne.dateDebut, 'yyyy-MM-dd'),
                                dateFin: new DatePipe('fr').transform(campagne.dateFin, 'yyyy-MM-dd'),
                                statutLibelle: StatutsCampagne[`${campagne.statut}`],
                                nbTactiques: (campagne.idsTactiques) ? campagne.idsTactiques.length : 0
                            })),
                            switchMap(campagne => [
                                createCampagneSuccess({ campagne }),
                                go({ payload: { path: [`/campagnes/${campagne.idCampagne}`] } })
                            ]
                            ),
                            catchError((error: any) => of(createCampagneFail({ error })))
                        )
                )
            );
    });

    createCampagneSuccess$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(createCampagneSuccess),
                switchMap(() => [
                    addOneAppNotification({
                        payload: new SuccessNotification({
                            summary: 'common.success',
                            detail: 'campagnes.create.successDetailsMessage',
                        }),
                    }),
                ]),
            );
    });


    updateCampagne$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateCampagne),
            switchMap((action) => {
                return this.campagneService.updateCampagne(action.campagne).pipe(
                    map(campagne => ({
                        ...campagne,
                        dateDebut: new DatePipe('fr').transform(campagne.dateDebut, 'yyyy-MM-dd'),
                        dateFin: new DatePipe('fr').transform(campagne.dateFin, 'yyyy-MM-dd'),
                        statutLibelle: StatutsCampagne[`${campagne.statut}`],
                        nbTactiques: (campagne.idsTactiques) ? campagne.idsTactiques.length : 0
                    })),

                    switchMap(() => {
                        const updatedCampagne: Update<Campagne> = {
                            id: action.campagne.idCampagne,
                            changes: {
                                ...action.campagne,
                            },
                        };
                        return [updateCampagneSuccess({ campagne: updatedCampagne }),
                        go({ payload: { path: [`/campagnes`] } })];

                    }),

                    catchError((error: any) => of(updateCampagneFail({ error })))
                );
            })
        );
    });

    updateCampagneSuccess$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(updateCampagneSuccess),
                switchMap(() => [
                    addOneAppNotification({
                        payload: new SuccessNotification({
                            summary: 'common.success',
                            detail: 'campagnes.update.successDetailsMessage',
                        }),
                    }),
                ]),
            );
    });

    deleteCampagne$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(deleteCampagne),
                switchMap(action =>
                    this.campagneService.deleteCampagne(action.currentCampagneId)
                        .pipe(
                            switchMap(() => [
                                deleteCampagneSuccess({ idCampagne: action.currentCampagneId }),
                                addOneAppNotification({
                                    payload: new SuccessNotification({
                                        summary: 'common.success',
                                        detail: 'campagnes.delete.successDetailsMessage',
                                    }),
                                }),
                                go({ payload: { path: [`campagnes`] } })
                            ]),
                            catchError((error: any) => of(deleteCampagneFail({ error })))
                        )
                )
            );
    });

    duplicateCampagne$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(duplicateCampagne),
                mergeMap(action =>
                    this.campagneService.duplicateCampagne(action.currentCampagneId, action.anneeIcrement)
                        .pipe(
                            map(campagne => ({
                                ...campagne,
                                dateDebut: new DatePipe('fr').transform(campagne.dateDebut, 'yyyy-MM-dd'),
                                dateFin: new DatePipe('fr').transform(campagne.dateFin, 'yyyy-MM-dd'),
                                statutLibelle: StatutsCampagne[`${campagne.statut}`],
                                nbTactiques: (campagne.idsTactiques)
                                    ? campagne.idsTactiques.length
                                    : 0
                            })),
                            map(campagne => duplicateCampagneSuccess({ campagne })),
                            catchError((error: any) => of(duplicateCampagneFail({ error })))
                        )
                )
            );
    });

    duplicateCampagneSuccess$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(duplicateCampagneSuccess),
                switchMap(() => [
                    addOneAppNotification({
                        payload: new SuccessNotification({
                            summary: 'common.success',
                            detail: 'campagnes.duplicate.successDetailsMessage',
                        }),
                    }),
                ]),
            );
    });

    crudCampagneFail$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(loadCampagnesFail, createCampagneFail, updateCampagneFail, deleteCampagneFail, duplicateCampagneFail),
                switchMap(({ error }) => [
                    addOneAppNotification({
                        payload: new ErrorNotification({
                            summary: 'common.error',
                            detail: error,
                        }),
                    }),
                ]),
            );
    });

    constructor(
        private actions$: Actions<CampagneActions>,
        private campagneService: CampagneService
    ) { }

}
