import { Params } from '@angular/router';

import { createSelector, createFeatureSelector } from '@ngrx/store';
import { SelectItem } from 'primeng/api';

import { tactiqueAdapter, TactiqueState } from '../reducers/tactique.reducer';
import { Tactique } from '../../models/tactique';
import { EditMode } from '../../models/edit-mode.enum';
import { Campagne } from '../../models';
import { getRouterDataEditMode } from './crud-form.selectors';
import { getRouterParams, getRouterQueryParams } from './router.selectors';
import { selectAllCampagnes } from './campagne.selectors';
import { getChoixAvailableNiveaux } from './modele-solicitation.selectors';

export const getTactiqueState = createFeatureSelector<TactiqueState>('tactiques');

const {
    selectAll,
} = tactiqueAdapter.getSelectors();

export const selectAllTactiques = createSelector(
    getTactiqueState,
    selectAll
);

export const areTactiquesLoading = createSelector(
    getTactiqueState,
    (state: TactiqueState) => state && state.loading || false,
);

export const areTactiquesLoaded = createSelector(
    getTactiqueState,
    (state: TactiqueState) => state && state.loaded || false,
);

export const getCurrentTactiqueId = createSelector(
    getRouterParams,
    (params: Params) =>
        params && params.idTactique || null
);

export const getCurrentTactiqueQueryParamsCampagne = createSelector(
    getRouterQueryParams,
    selectAllCampagnes,
    (queryParams: Params, campagnes: Campagne[]) =>
        queryParams && Array.isArray(campagnes) && campagnes.find(c => c.idCampagne === queryParams.idCampagne) || null
);

export const getCurrentTactique = createSelector(
    getCurrentTactiqueId,
    selectAllTactiques,
    getRouterDataEditMode,
    getChoixAvailableNiveaux,
    getCurrentTactiqueQueryParamsCampagne,
    (idTactique: string, tactiques: Tactique[], editMode: EditMode, niveaux: SelectItem[], campagneAssocie: Campagne) => {
        if (editMode === EditMode.CREATION) {
            return {
                communication: {
                    niveau: Array.isArray(niveaux) && niveaux[0] && niveaux[0].value || null,
                    idCategorieCommunication: null,
                    idCanal: null,
                    idLibreService: null,
                    idGabaritCourriel: null,
                },
                description: null,
                idDatasetPowerBI: null,
                nomDatasetPowerBI: null,
                idCampagne: campagneAssocie && campagneAssocie.idCampagne || null,
                dateDebut: campagneAssocie && campagneAssocie.dateDebut || null,
                dateFin: campagneAssocie && campagneAssocie.dateFin || null,
            } as Tactique;
        } else if (tactiques && idTactique) {
            return tactiques.find(tactique =>
                tactique.idTactique.toString() === idTactique.toString()
            ) || null;
        }
        return null;
    }
);

export const shouldLoadTactiques = createSelector(
    areTactiquesLoading,
    areTactiquesLoaded,
    (isLoading: boolean, isLoaded: boolean) => {
        if (isLoading) {
            return { shouldLoad: false, isLoaded, isLoading };
        } else {
            if (!isLoaded) {
                return { shouldLoad: true, isLoaded, isLoading };
            }

            return { shouldLoad: false, isLoaded, isLoading };
        }
    }
);
