import { Inject, Injectable } from '@angular/core';

import { WINDOW } from '../tokens/window.token';

@Injectable({
    providedIn: 'root'
})
export class WindowRefService {
    constructor(
        /**
         * Injection token representing the javascript window object
         * @private
         */
        @Inject(WINDOW) private window: Window,
    ) { }

    /**
     * Accessor for native javascript window object
     */
    public get nativeWindow(): any {
        return this.window;
    }
}
