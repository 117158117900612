import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { ActivationEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';

import { filter, takeUntil, tap } from 'rxjs/operators';

import { BusyRouterService, ComponentBase, ComponentFeatures, DoInit, InheritsBaseLifecycleHooks } from '@ngxhq/common-ui';
import { OpenIdService } from '@ngxhq/security';

import { I18nService } from './core/services';
import { BrowserDetectionService } from './browser-detection/services/browser-detection.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
@ComponentFeatures([
    InheritsBaseLifecycleHooks()
])
export class AppComponent extends ComponentBase implements DoInit {
    public layout = 'shell';
    public isMainMenuExpanded = true;
    public autoLogoutIn: number | undefined;

    private initNavigation = true;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private busyRouterService: BusyRouterService,
        private openIdService: OpenIdService,
        private zone: NgZone,
        private i18nService: I18nService,
        private browserDetectionService: BrowserDetectionService,
    ) {
        super();
    }

    public doInit(): void {
        this.activateApplicationAutoLayout();
        this.activateNavigationBusyState();
        this.activateMainMenuAutoExpansion();
        this.listenToSessionTimeout();

        // Initialize translation service
        this.i18nService.init();

        // Detect browser version to display warning or error messages to user using these browsers
        this.browserDetectionService.init();
    }

    private activateApplicationAutoLayout(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof ActivationEnd || event instanceof NavigationEnd),
                tap((event: ActivationEnd | NavigationEnd) => {
                    if (event instanceof ActivationEnd) {
                        this.setComponentLayout(event as ActivationEnd);
                    } else if (event instanceof NavigationEnd) {
                        this.setMenuSelection(event as NavigationEnd);
                    }
                }),
                takeUntil(this.destroyed)
            )
            .subscribe();
    }

    private setComponentLayout(event: ActivationEnd): void {

        const newLayout = event.snapshot.data &&
            event.snapshot.data.layout ?
            event.snapshot.data.layout : 'shell';

        if (newLayout !== this.layout) {
            this.layout = newLayout;
            this.changeDetectorRef.detectChanges();
        }
    }

    private activateNavigationBusyState(): void {
        this.busyRouterService.showBusyWhenNavigating()
            .pipe(takeUntil(this.destroyed))
            .subscribe();
    }

    private activateMainMenuAutoExpansion(): void {
        this.router.events
            .pipe(
                tap((event) => {
                    if (event instanceof NavigationStart) {
                        this.detectInitialNavigation();
                    } else if (event instanceof NavigationEnd) {
                        this.handleMainMenuExpansion();
                    }
                }),
                takeUntil(this.destroyed)
            )
            .subscribe();
    }

    private detectInitialNavigation(): void {
        this.initNavigation = !this.router.navigated;
    }

    private handleMainMenuExpansion(): void {
        const isMobile = document.documentElement.clientWidth <= 480;

        if (isMobile) {
            this.isMainMenuExpanded = false;
        } else if (this.initNavigation) {
            this.isMainMenuExpanded = true;
        }
    }

    private listenToSessionTimeout(): void {

        this.openIdService.idleTimeoutRemainingSeconds
            .subscribe((x: number | undefined) => {
                this.zone.run(() => {
                    this.autoLogoutIn = x;
                });
            }, (err: any) => {
                console.error('idleTimeoutRemainingSeconds failed', err);
                this.zone.run(() => {
                    this.autoLogoutIn = undefined;
                });
            });

    }

    /**
     * https://jira.hydro.qc.ca/browse/DSIMT-21795
     * workarount de la gestion du menu
     * Pour les changements de route à même le router-outlet du main-content
     **/
    private setMenuSelection(event: NavigationEnd): void {
        const section =  this.getMenuSectionByUrl(event.url);
        if (section) {
           this.setMenuClass(section);
        }
    }

    private getMenuSectionByUrl(url: string): string {
        let section = '';

        const segments = url.split('/');

        if (segments && segments.length >= 1) {
            section = segments[1];

            if (section.includes('?')) {
                section = section.split('?')[0];
            }
        }
        return section;
    }

    private setMenuClass(section: string) {
        const menuItems = document.querySelectorAll<Element>('ul.hq-ui-panelmenu li');
        if (menuItems) {
            menuItems.forEach((item: any) => {
                // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
                if (item.innerText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') === section.toLowerCase()) {
                    if (!item.classList.contains('hq-ui-state-active')) {
                        item.classList.add('hq-ui-state-active');
                    }
                } else if (item.classList.contains('hq-ui-state-active')) {
                    item.classList.remove('hq-ui-state-active');
                }
            });
        }
    }
}
