import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { of } from 'rxjs';
import { mergeMap, map, catchError, concatMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import {
    TactiqueActions,
    loadTactiques,
    loadTactiquesSuccess,
    loadTactiquesFail,
    updateTactique,
    updateTactiqueSuccess,
    updateTactiqueFail,
    createTactique,
    createTactiqueSuccess,
    createTactiqueFail,
    deleteTactique,
    deleteTactiqueSuccess,
    deleteTactiqueFail,
    associerTactique,
    associerTactiqueSuccess,
    associerTactiqueFail,
} from '../actions/tactique.actions';
import { go, addOneAppNotification, setShouldLoadCampagnes, loadExecutions } from '../../../core/store/actions';
import { selectAllCategoriesCommunication, selectAllCanaux, selectAllGabaritsCourriel } from '../selectors';
import { SuccessNotification, ErrorNotification, StatutsTactique, StatutsTactiqueSorted, Tactique } from '../../../core/models';
import { TactiqueService } from '../../services/tactique.service';
import { ModeleSolicitationState } from '../reducers/modele-solicitation.reducer';

@Injectable()
export class TactiqueEffects {

    loadTactiques$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(loadTactiques),
                mergeMap(() => this.tactiqueService.getTactiques()
                    .pipe(
                        withLatestFrom(
                            this.store.pipe(select(selectAllCategoriesCommunication)),
                            this.store.pipe(select(selectAllCanaux)),
                            this.store.pipe(select(selectAllGabaritsCourriel))
                        ),
                        map(([tactiques, arrayCategorieCommunication, arrayCanaux, arrayGabarits]) =>
                            tactiques.map(
                                tactique => ({
                                    ...tactique,

                                    dateDebut: new DatePipe('fr').transform(tactique.dateDebut, 'yyyy-MM-dd'),
                                    dateFin: new DatePipe('fr').transform(tactique.dateFin, 'yyyy-MM-dd'),
                                    datePremiereExec: new DatePipe('fr').transform(tactique.datePremiereExec, 'yyyy-MM-dd'),
                                    dateDerniereExec: new DatePipe('fr').transform(tactique.dateDerniereExec, 'yyyy-MM-dd'),
                                    statutLibelle: StatutsTactique[`${tactique.statut}`],
                                    statutLibelleSorted: StatutsTactiqueSorted[`${tactique.statut}`],
                                    libelleCategorieCommunication: arrayCategorieCommunication.find((element) =>
                                        element.idCategorieCommunication === tactique.communication.idCategorieCommunication).description || '',
                                    libelleCanal: arrayCanaux.find((element) => element.idCanal === tactique.communication.idCanal).description || '',
                                    libelleGabarit: arrayGabarits.find((element) =>
                                        element.idGabaritCourriel === tactique.communication.idGabaritCourriel).description || ''
                                }))
                        ),
                        map(tactiques => loadTactiquesSuccess({ tactiques })),
                        catchError((error: any) => of(loadTactiquesFail({ error })))
                    )
                )
            );
    });

    loadTactiquesSuccess$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(loadTactiquesSuccess),
                switchMap(() => [
                    loadExecutions()
                ]),
            );
    });


    createTactique$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(createTactique),
                concatMap(action =>
                    this.tactiqueService.createTactique(action.tactique)
                        .pipe(
                            withLatestFrom(
                                this.store.pipe(select(selectAllCategoriesCommunication)),
                                this.store.pipe(select(selectAllCanaux)),
                                this.store.pipe(select(selectAllGabaritsCourriel))
                            ),
                            map(([tactique, arrayCategorieCommunication, arrayCanaux, arrayGabarits]) => ({
                                ...tactique,
                                dateDebut: new DatePipe('fr').transform(tactique.dateDebut, 'yyyy-MM-dd'),
                                dateFin: new DatePipe('fr').transform(tactique.dateFin, 'yyyy-MM-dd'),
                                datePremiereExec: new DatePipe('fr').transform(tactique.datePremiereExec, 'yyyy-MM-dd'),
                                dateDerniereExec: new DatePipe('fr').transform(tactique.dateDerniereExec, 'yyyy-MM-dd'),
                                statutLibelle: StatutsTactique[`${tactique.statut}`],
                                statutLibelleSorted: StatutsTactiqueSorted[`${tactique.statut}`],
                                libelleCategorieCommunication: arrayCategorieCommunication.find((element) =>
                                    element.idCategorieCommunication === tactique.communication.idCategorieCommunication).description || '',
                                libelleCanal: arrayCanaux.find((element) => element.idCanal === tactique.communication.idCanal).description || '',
                                libelleGabarit: arrayGabarits.find((element) =>
                                    element.idGabaritCourriel === tactique.communication.idGabaritCourriel).description || ''
                            })),
                            switchMap(tactique => [
                                createTactiqueSuccess({ tactique }),
                                go({ payload: { path: [`/tactiques/${tactique.idTactique}`] } })
                            ]
                            ),
                            catchError((error: any) => of(createTactiqueFail({ error })))
                        )
                )
            );
    });

    createTactiqueSuccess$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(createTactiqueSuccess),
                switchMap(() => [
                    setShouldLoadCampagnes(),
                    addOneAppNotification({
                        payload: new SuccessNotification({
                            summary: 'common.success',
                            detail: 'tactiques.create.successDetailsMessage',
                        }),
                    }),
                    //      go({ payload: { path: ['/tactiques'] } }),
                ]),
            );
    });



    updateTactique$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateTactique),
            switchMap((action) => {
                return this.tactiqueService.updateTactique(action.tactique).pipe(
                    withLatestFrom(
                        this.store.pipe(select(selectAllCategoriesCommunication)),
                        this.store.pipe(select(selectAllCanaux)),
                        this.store.pipe(select(selectAllGabaritsCourriel))
                    ),
                    map(([tactique, arrayCategorieCommunication, arrayCanaux, arrayGabarits]) => ({
                        ...tactique,
                        dateDebut: new DatePipe('fr').transform(tactique.dateDebut, 'yyyy-MM-dd'),
                        dateFin: new DatePipe('fr').transform(tactique.dateFin, 'yyyy-MM-dd'),
                        datePremiereExec: new DatePipe('fr').transform(tactique.datePremiereExec, 'yyyy-MM-dd'),
                        dateDerniereExec: new DatePipe('fr').transform(tactique.dateDerniereExec, 'yyyy-MM-dd'),
                        statutLibelle: StatutsTactique[`${tactique.statut}`],
                        statutLibelleSorted: StatutsTactiqueSorted[`${tactique.statut}`],
                        libelleCategorieCommunication: arrayCategorieCommunication.find((element) =>
                            element.idCategorieCommunication === tactique.communication.idCategorieCommunication).description || '',
                        libelleCanal: arrayCanaux.find((element) => element.idCanal === tactique.communication.idCanal).description || '',
                        libelleGabarit: arrayGabarits.find((element) =>
                            element.idGabaritCourriel === tactique.communication.idGabaritCourriel).description || ''
                    })),
                    switchMap((tactique) => {
                        const updatedTactique: Update<Tactique> = {
                            id: tactique.idTactique,
                            changes: {
                                ...tactique,
                            },
                        };
                        return [updateTactiqueSuccess({ tactique: updatedTactique }),
                        go({ payload: { path: [`/tactiques`] } })];
                    }),
                    catchError((error: any) => of(updateTactiqueFail({ error })))
                );
            })
        );
    });

    updateTactiqueSuccess$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(updateTactiqueSuccess),
                switchMap(() => [
                    addOneAppNotification({
                        payload: new SuccessNotification({
                            summary: 'common.success',
                            detail: 'tactiques.update.successDetailsMessage',
                        }),
                    }),
                ]),
            );
    });

    deleteTactique$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(deleteTactique),
                switchMap(action =>
                    this.tactiqueService.deleteTactique(action.currentTactiqueId).pipe(
                        map(() => deleteTactiqueSuccess({ idTactique: action.currentTactiqueId })),
                        catchError((error: any) => of(deleteTactiqueFail({ error })))
                    )
                ),
            );
    });

    deleteTactiqueSuccess$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(deleteTactiqueSuccess),
                switchMap(() => [
                    addOneAppNotification({
                        payload: new SuccessNotification({
                            summary: 'common.success',
                            detail: 'tactiques.delete.successDetailsMessage',
                        }),
                    }),
                    setShouldLoadCampagnes(),
                ]),
            );
    });

    associerTactique$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(associerTactique),
            switchMap((action) => {
                return this.tactiqueService.associerTactique(action.currentTactiqueId).pipe(
                    withLatestFrom(
                        this.store.pipe(select(selectAllCategoriesCommunication)),
                        this.store.pipe(select(selectAllCanaux)),
                        this.store.pipe(select(selectAllGabaritsCourriel))
                    ),
                    map(([tactique, arrayCategorieCommunication, arrayCanaux, arrayGabarits]) => ({
                        ...tactique,
                        dateDebut: new DatePipe('fr').transform(tactique.dateDebut, 'yyyy-MM-dd'),
                        dateFin: new DatePipe('fr').transform(tactique.dateFin, 'yyyy-MM-dd'),
                        datePremiereExec: new DatePipe('fr').transform(tactique.datePremiereExec, 'yyyy-MM-dd'),
                        dateDerniereExec: new DatePipe('fr').transform(tactique.dateDerniereExec, 'yyyy-MM-dd'),
                        statutLibelle: StatutsTactique[`${tactique.statut}`],
                        statutLibelleSorted: StatutsTactiqueSorted[`${tactique.statut}`],
                        libelleCategorieCommunication: arrayCategorieCommunication.find((element) =>
                            element.idCategorieCommunication === tactique.communication.idCategorieCommunication).description || '',
                        libelleCanal: arrayCanaux.find((element) => element.idCanal === tactique.communication.idCanal).description || '',
                        libelleGabarit: arrayGabarits.find((element) =>
                            element.idGabaritCourriel === tactique.communication.idGabaritCourriel).description || ''
                    })),
                    switchMap((tactique) => {
                        const associatedTactique: Update<Tactique> = {
                            id: action.currentTactiqueId,
                            changes: {
                                ...tactique,
                            },
                        };
                        return [associerTactiqueSuccess({ tactique: associatedTactique })];
                    }),
                    catchError((error: any) => of(associerTactiqueFail({ error })))
                );
            })
        );
    });
    associerTactiqueSuccess$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(associerTactiqueSuccess),
                switchMap(() => [
                    addOneAppNotification({
                        payload: new SuccessNotification({
                            summary: 'common.success',
                            detail: 'tactiques.associer.successDetailsMessage',
                        }),
                    }),
                ]),
            );
    });

    crudTactiqueFail$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(loadTactiquesFail, createTactiqueFail, updateTactiqueFail, deleteTactiqueFail, associerTactiqueFail),
                switchMap(({ error }) => [
                    addOneAppNotification({
                        payload: new ErrorNotification({
                            summary: 'common.error',
                            detail: error,
                        }),
                    }),
                ]),
            );
    });

    constructor(
        private actions$: Actions<TactiqueActions>,
        private tactiqueService: TactiqueService,
        private store: Store<ModeleSolicitationState>
    ) { }

}
