import { CampagneService } from './campagne.service';
import { ExecutionService } from './execution.service';
import { I18nService } from './i18n.service';
import { ModeleSolicitationService } from './modeleSolicitation.service';
import { TactiqueService } from './tactique.service';
import { WindowRefService } from './window-ref.service';

export const coreServices = [
    I18nService,
    WindowRefService,
    TactiqueService,
    ModeleSolicitationService,
    ExecutionService,
    CampagneService
];

export * from './i18n.service';
export * from './window-ref.service';
export * from './tactique.service';
export * from './modeleSolicitation.service';
export * from './execution.service';
export * from './campagne.service';