export class Canal {

    constructor(
        public idCanal: string,
        public description: string
    ) { }

    static fromJSON(json: any): Canal {
        const object: Canal = Object.create(Canal.prototype);
        Object.assign(object, json);
        return object;
    }

    static createCanalModel(options?: Partial<Canal>): Canal {
        options = options || {};
        return new Canal(
            options.idCanal,
            options.description
        );
    }

    toString(): string {
        return `Canal: ${JSON.stringify(this)}`;
    }
}
