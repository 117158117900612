import { createReducer, on, Action } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';

import {
    loadCampagnes,
    loadCampagnesSuccess,
    loadCampagnesFail,
    createCampagne,
    createCampagneSuccess,
    createCampagneFail,
    updateCampagne,
    updateCampagneSuccess,
    updateCampagneFail,
    deleteCampagne,
    deleteCampagneSuccess,
    deleteCampagneFail,
    duplicateCampagne,
    duplicateCampagneSuccess,
    duplicateCampagneFail,
    setShouldLoadCampagnes,
} from '../actions/campagne.actions';
import { Campagne } from '../../models/campagne';


export interface CampagneState extends EntityState<Campagne> {
    currentCampagneId: string | null,
    loading: boolean,
    loaded: boolean,
    error: string | null
}
export function selectCampagneId(a: Campagne): string {
    return a.idCampagne;
}

export const campagneAdapter: EntityAdapter<Campagne> = createEntityAdapter<Campagne>({
    selectId: selectCampagneId,
    sortComparer: false
});

export const initialState: CampagneState = campagneAdapter.getInitialState({
    // additional entity state properties
    currentCampagneId: null,
    loading: false,
    loaded: false,
    error: null,
});

export const campagnesReducer = createReducer<CampagneState>(
    initialState,
    on(loadCampagnes, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),

    on(loadCampagnesSuccess, (state, { campagnes }) => {
        return campagneAdapter.setAll(campagnes, {
            ...state,
            loading: false,
            loaded: true,
            error: null,
        });
    }),

    on(loadCampagnesFail, (state, action): CampagneState => {
        return {
            ...state,
            error: action.error,
            loading: false,
            loaded: false,
        };
    }),

    on(setShouldLoadCampagnes, (state) => {
        return {
            ...state,
            loaded: false,
        };
    }),

    on(deleteCampagne, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),

    on(deleteCampagneSuccess, (state, { idCampagne }) => {
        return campagneAdapter.removeOne(idCampagne, {
            ...state,
            loading: false,
            error: null,
        });
    }),

    on(deleteCampagneFail, (state, action): CampagneState => {
        return {
            ...state,
            error: action.error,
            loading: false,
        };
    }),

    on(duplicateCampagne, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),

    on(duplicateCampagneSuccess, (state, { campagne }) => {
        return campagneAdapter.addOne(campagne, {
            ...state,
            loading: false,
            loaded: false,
            error: null,
        });
    }),

    on(duplicateCampagneFail, (state, action): CampagneState => {
        return {
            ...state,
            error: action.error,
            loading: false,
        };
    }),

    on(createCampagne, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),

    on(createCampagneSuccess, (state, { campagne }) => {
        return campagneAdapter.addOne(campagne, {
            ...state,
            loading: false,
            error: null,
        });
    }),

    on(createCampagneFail, (state, action): CampagneState => {
        return {
            ...state,
            error: action.error,
            loading: false,
        };
    }),

    on(updateCampagne, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),

    on(updateCampagneSuccess, (state, { campagne }) => {

        return campagneAdapter.updateOne(campagne,
            {
                ...state,
                loading: false,
                error: null,
            }
        );

    }),
    on(updateCampagneFail, (state, action): CampagneState => {
        return {
            ...state,
            error: action.error,
            loading: false,
        };
    })

);

export function reducer(state: CampagneState | undefined, action: Action) {
    return campagnesReducer(state, action);
}
