import { createReducer, on } from '@ngrx/store';
import { CrudFormActions, resetForm, updateForm } from '../actions';


export interface CrudFormState {
    tactiqueForm: {
        idTactique?: string,
        idCampagne?: string,
        description?: string,
        idDatasetPowerBI?: string,
        dateDebut?: Date,
        dateFin?: Date,
        niveau?: string,
        categorie?: string,
        canal?: string,
        libreService?: string,
        gabaritCourriel?: string
    },
    executionForm: {
        idCampagne?: string,
        idExecution?: string,
        idTactique?: number,
        dateDebut?: Date,
        dateFin?: Date,
        maxCommJourSem?: number,
        maxCommJourFDS?: number,
    }
}


export const initialState: CrudFormState = {
    tactiqueForm: {
    },
    executionForm: {
    }
};

export const crudFormReducer = createReducer<CrudFormState>(
    initialState,
    on(updateForm, (state, action) => {
        return {
            ...state,
            [action.formId]: {
                ...action.value
            }
        };
    }),
    on(resetForm, (state, action) => {
        return {
            ...state,
            [action.formId]: {
                ...initialState[action.formId]
            }
        };
    }),
);


export function reducer(state: CrudFormState | undefined, action: CrudFormActions) {
    return crudFormReducer(state, action);
}
