import { createReducer, on, Action } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';

import {
    loadExecutions,
    loadExecutionsSuccess,
    loadExecutionsFail,
    createExecution,
    createExecutionSuccess,
    createExecutionFail,
    updateExecution,
    updateExecutionSuccess,
    updateExecutionFail,
    deleteExecution,
    deleteExecutionSuccess,
    deleteExecutionFail,
    arreterExecution,
    arreterExecutionSuccess,
    arreterExecutionFail,
    suspendreExecution,
    suspendreExecutionSuccess,
    suspendreExecutionFail,
    reprendreExecution,
    reprendreExecutionSuccess,
    reprendreExecutionFail,
} from '../actions/execution.actions';
import { Execution } from '../../../core/models/execution';
import {
    deleteCampagneSuccess,
    deleteTactiqueSuccess,
    duplicateCampagneSuccess,
} from '../../../core/store/actions';

export interface ExecutionState extends EntityState<Execution> {
    loading: boolean;
    loaded: boolean;
    error: string;
}


export function selectExecutionId(a: Execution): string {
    return a.idCalendrierExecution;
}

export const executionAdapter: EntityAdapter<Execution> = createEntityAdapter<Execution>({
    selectId: selectExecutionId,
    sortComparer: false,
});

export const initialState: ExecutionState = executionAdapter.getInitialState({
    // additional entity state properties
    loading: false,
    loaded: false,
    error: null,
});

export const executionsReducer = createReducer<ExecutionState>(
    initialState,
    on(loadExecutions, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(loadExecutionsSuccess, (state, { executions }) => {
        return executionAdapter.setAll(executions, {
            ...state,
            loading: false,
            loaded: true,
            error: null,
        });
    }),
    on(loadExecutionsFail, (state, action): ExecutionState => {
        return {
            ...state,
            error: action.error,
            loading: false,
            loaded: false,
        };
    }),

    on(deleteExecution, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(deleteExecutionSuccess, (state, { idExecution }) => {
        return executionAdapter.removeOne(idExecution, {
            ...state,
            loading: false,
            error: null,
        });
    }),
    on(deleteExecutionFail, (state, action): ExecutionState => {
        return {
            ...state,
            error: action.error,
            loading: false,
        };
    }),

    on(createExecution, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(createExecutionSuccess, (state, { execution }) => {
        return executionAdapter.addOne(execution, {
            ...state,
            loading: false,
            error: null,
        });
    }),
    on(createExecutionFail, (state, action): ExecutionState => {
        return {
            ...state,
            error: action.error,
            loading: false,
        };
    }),

    on(updateExecution, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(updateExecutionSuccess, (state, { execution }) => {

        return executionAdapter.updateOne(execution,
            {
                ...state,
                loading: false,
                error: null,
            }
        );
    }),
    on(updateExecutionFail, (state, action): ExecutionState => {
        return {
            ...state,
            error: action.error,
            loading: false,
        };
    }),
    on(arreterExecution,
        suspendreExecution,
        reprendreExecution,
        (state) => {
            return {
                ...state,
                loading: true,
            };
        }),
    on(arreterExecutionSuccess,
        suspendreExecutionSuccess,
        reprendreExecutionSuccess,
        (state, { execution }) => {

            return executionAdapter.updateOne(execution,
                {
                    ...state,
                    loading: false,
                    error: null,
                }
            );
        }),
    on(arreterExecutionFail,
        suspendreExecutionFail,
        reprendreExecutionFail,
        (state, action): ExecutionState => {
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        }),
    on(duplicateCampagneSuccess,
        (state) => {
            return {
                ...state,
                loaded: false,
            };
        }),
    on(deleteCampagneSuccess, (state, { idCampagne }) => {
        const keys = Object.values(state.entities)
            .filter(execution => execution.idCampagne === idCampagne)
            .map((execution) => execution.idCalendrierExecution);
        return executionAdapter.removeMany(keys, state);
    }),
    on(deleteTactiqueSuccess, (state, { idTactique }) => {
        const keys = Object.values(state.entities)
            .filter(execution => execution.idTactique === idTactique)
            .map((execution) => execution.idCalendrierExecution);
        return executionAdapter.removeMany(keys, state);
    }),

);



export function reducer(state: ExecutionState | undefined, action: Action) {
    return executionsReducer(state, action);
}

