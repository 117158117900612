import { RouterEffects } from './router.effects';
import { AppNotificationEffects } from './app-notifications.effects';
import { UserEffects } from './user.effects';
import { CampagneEffects } from './campagne.effects';
import { ModeleSolicitationEffects } from './modele-solicitation.effects';
import { TactiqueEffects } from './tactique.effects';
import { ExecutionEffects } from './execution.effects';

export const coreEffects: any[] = [
    RouterEffects,
    AppNotificationEffects,
    UserEffects,
    CampagneEffects,
    ModeleSolicitationEffects,
    TactiqueEffects,
    ExecutionEffects
];

export * from './router.effects';
export * from './app-notifications.effects';
export * from './user.effects';
export * from './campagne.effects';
export * from './modele-solicitation.effects';
export * from './tactique.effects';
export * from './execution.effects';

