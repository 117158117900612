
export class Communication {

    constructor(
        public idCommunication: number,
        public idCategorieCommunication: string,
        public idCanal: string,
        public idGabaritCourriel: string,
        public idGabaritNotif: string,
        public idLibreService: string,
        public dateDebut: string,
        public dateFin: string,
        public niveau: string,
        public codeEvenementEnvoieCourriel: string,
        public codeEvenementAccesCourriel: string,
        public codeEvenementEnvoieNotif: string,
        public codeEvenementAccesNotif: string,
    ) { }

    static fromJSON(json: any): Communication {
        const object: Communication = Object.create(Communication.prototype);
        Object.assign(object, json);
        return object;
    }

    static createCommunicationModel(options?: Partial<Communication>): Communication {
        options = options || {};
        return new Communication(
            options.idCommunication,
            options.idCategorieCommunication,
            options.idCanal,
            options.idGabaritCourriel,
            options.idGabaritNotif,
            options.idLibreService,
            options.dateDebut,
            options.dateFin,
            options.niveau,
            options.codeEvenementEnvoieCourriel,
            options.codeEvenementAccesCourriel,
            options.codeEvenementEnvoieNotif,
            options.codeEvenementAccesNotif,
        );
    }

    toString(): string {
        return `Communication: ${JSON.stringify(this)}`;
    }
}
