import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { loadCampagnes, loadModeleSolicitations } from '../store/actions';
import { CampagneState } from '../store/reducers/campagne.reducer';

@Injectable({
    providedIn: 'root'
})
export class MainLoadingResolver implements Resolve<boolean> {

    constructor(private readonly store: Store<CampagneState>) { }

    resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
        this.store.dispatch(loadCampagnes());
        this.store.dispatch(loadModeleSolicitations());
        return true;
    }
}
