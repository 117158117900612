<div *ngIf="layout === 'shell'">
    <app-header (expandedChange)='isMainMenuExpanded=$event' [expanded]="isMainMenuExpanded"></app-header>
    <main [ngClass]="{'menu--is-expanded': isMainMenuExpanded}">
        <app-main-menu (expandedChange)='isMainMenuExpanded=$event' [expanded]="isMainMenuExpanded"></app-main-menu>
        <div class="main-content" [hqBusy]="'main'">
            <router-outlet></router-outlet>
        </div>
        <section *ngIf="(autoLogoutIn || 9999) <= 120" class="hq-app-message--warning" aria-live="polite">
            <div class="hq-app-message__inner">
                <div class="hq-app-message"><span
                        class="hq-app-message__icon appicons ic-exclamation-triangle"></span>Votre
                    session expirera dans {{ autoLogoutIn }} secondes.
                </div>
            </div>
        </section>
    </main>
</div>
<div *ngIf="layout === 'fullscreen'">
    <router-outlet></router-outlet>
</div>
