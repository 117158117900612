import { Injectable } from '@angular/core';

import { tap } from 'rxjs/operators';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { addOneAppNotification, AppNotificationsActions } from '../actions/app-notifications.actions';

@Injectable()
export class AppNotificationEffects {

    public appNotification$ = createEffect(() => this.actions$.pipe(
        ofType(addOneAppNotification),
        tap(({
            payload: { summary, detail, severity: type, arguments: args }
        }) => {
            if (type === 'success') {
                this.toastService[type](
                    this.translateService.instant(detail || '', args),
                    this.translateService.instant(summary, args),
                    {
                        positionClass: 'toast-top-center',
                        timeOut: 5000,
                        progressBar: true,
                        closeButton: true
                    }
                );
            } else {

                // khaled, j'ai ajoutè cette condition puisque cela me cause du problème aléatoire..
                if (type && detail && args) {
                    this.toastService[type](
                        this.translateService.instant(detail || '', args),
                        this.translateService.instant(summary, args),
                        {
                            positionClass: 'toast-top-center',
                            timeOut: 5000,
                            closeButton: true
                        }
                    );
                } else {

                    if (type && detail && detail.length) {
                        this.toastService[type](
                            this.translateService.instant(detail),
                            this.translateService.instant(summary),
                            {
                                positionClass: 'toast-top-center',
                                timeOut: 5000,
                                closeButton: true
                            }
                        );
                    }
                }

            }
        }
        )
    ), { dispatch: false });

    constructor(
        private actions$: Actions<AppNotificationsActions>,
        private toastService: ToastrService,
        private translateService: TranslateService
    ) { }

}
