import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { resetForm, updateForm } from '../../core/store/actions';
import { CoreState } from '../../core/store/reducers';

@Directive({
    selector: '[crudForm]'
})
export class CrudFormDirective implements OnInit, OnDestroy {
    @Input('crudForm') formId: string;
    formChange: Subscription;

    constructor(
        private formGroupDirective: FormGroupDirective,
        private store: Store<CoreState>) {
    }

    public ngOnInit(): void {
        // On initialise la formulaire a la valeur par défaut.
        this.store.dispatch(updateForm({
            formId: this.formId,
            value: this.formGroupDirective.form.getRawValue()
        }));

        // Mettre a jour le state du formulaire dans le store lorsque sa valeur change.
        this.formChange = this.formGroupDirective.form.valueChanges.pipe(
            debounceTime(300),
        ).subscribe(() => {
            this.store.dispatch(updateForm({
                formId: this.formId,
                value: this.formGroupDirective.form.getRawValue()
            }));
        });
    }

    public ngOnDestroy() {
        if (this.formChange) {
            //On close la value change subscription
            this.formChange.unsubscribe();
            //On reinitialise le formulaire pour ne pas conserver d'ancienne valeurs.
            this.store.dispatch(resetForm({ formId: this.formId }));
        }
    }

}