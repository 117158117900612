import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Execution, ExecutionAjouterModifierRequest } from '../models/execution';
import { AppConfiguration } from '../../shared/config/app-config.model';

@Injectable({
    providedIn: 'root',
})
export class ExecutionService {

    private headers = new HttpHeaders({ 'content-type': 'application/json' });

    constructor(private http: HttpClient,
        private appConfig: AppConfiguration) {
    }

    private handleError(err: any) {
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `Une erreur est survenue: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            //errorMessage = `Backend code de retour ${err.status}: ${err.body.error}`;
            if (err.error) {
                errorMessage = err.error.message;
            }
        }

        if (!errorMessage) {
            errorMessage = 'Erreur inconnue (Module exécution)....';
        }

        console.error('errorMessage=' + errorMessage);
        return throwError(errorMessage);
    }

    getExecutions(): Observable<Execution[]> {
        const url = `${this.appConfig.baseBackendUrl}/calendriersexecution`;

        return this.http.get<Execution[]>(url, { headers: this.headers })
            .pipe(
                catchError(this.handleError)
            );
    }

    getExecution(id: string): Observable<{}> {
        const url = `${this.appConfig.baseBackendUrl}/calendriersexecution/${id}`;
        return this.http.get<Execution>(url, { headers: this.headers })
            .pipe(
                catchError(this.handleError)
            );
    }


    createExecution(execution: Execution): Observable<Execution> {
        const url = `${this.appConfig.baseBackendUrl}/calendriersexecution`;
        const newExecution: ExecutionAjouterModifierRequest = new ExecutionAjouterModifierRequest(execution.idTactique, execution.dateDebut,
            execution.dateFin, execution.maxCommJourSem, execution.maxCommJourFDS, execution.prioritaire);

        //        const newExecution = { ...execution, id: null };
        return this.http.post<Execution>(url, newExecution, { headers: this.headers })
            .pipe(
                catchError(this.handleError)
            );
    }


    deleteExecution(id: string): Observable<{}> {
        const url = `${this.appConfig.baseBackendUrl}/calendriersexecution/${id}`;
        return this.http.delete<Execution>(url, { headers: this.headers })
            .pipe(
                catchError(this.handleError)
            );
    }


    updateExecution(execution: Execution): Observable<Execution> {
        const url = `${this.appConfig.baseBackendUrl}/calendriersexecution/${execution.idCalendrierExecution}`;

        const newExecution: ExecutionAjouterModifierRequest = new ExecutionAjouterModifierRequest(execution.idTactique, execution.dateDebut,
            execution.dateFin, execution.maxCommJourSem, execution.maxCommJourFDS, execution.prioritaire);
        return this.http.put<Execution>(url, newExecution, { headers: this.headers })
            .pipe(
                // Return the execution on an update
                map(() => execution),
                catchError(this.handleError)
            );
    }


    ///api/v1/calendriersexecution/{{idCalendrierExecution}}?statut=REPRENDRE
    arreterExecution(id: string): Observable<Execution> {

        const url = `${this.appConfig.baseBackendUrl}/calendriersexecution/${id}?statut=ARRETER`;

        return this.http.patch<Execution>(url, { headers: this.headers })
            .pipe(
                catchError(this.handleError)
            );
    }

    suspendreExecution(id: string): Observable<Execution> {
        const url = `${this.appConfig.baseBackendUrl}/calendriersexecution/${id}?statut=SUSPENDRE`;
        return this.http.patch<Execution>(url, { headers: this.headers })
            .pipe(
                catchError(this.handleError)
            );
    }

    reprendreExecution(id: string): Observable<Execution> {
        const url = `${this.appConfig.baseBackendUrl}/calendriersexecution/${id}?statut=REPRENDRE`;
        return this.http.patch<Execution>(url, { headers: this.headers })
            .pipe(
                catchError(this.handleError)
            );
    }

}
