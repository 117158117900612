export class GabaritCourriel {

    constructor(
        public idGabaritCourriel: string,
        public description: string
    ) { }

    static fromJSON(json: any): GabaritCourriel {
        const object: GabaritCourriel = Object.create(GabaritCourriel.prototype);
        Object.assign(object, json);
        return object;
    }

    static createGabaritCourrielModel(options?: Partial<GabaritCourriel>): GabaritCourriel {
        options = options || {};
        return new GabaritCourriel(
            options.idGabaritCourriel,
            options.description
        );
    }

    toString(): string {
        return `GabaritCourriel: ${JSON.stringify(this)}`;
    }
}
