import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonUiModule } from '@ngxhq/common-ui';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { SharedModule as PrimeSharedModule } from 'primeng/api';
import { TranslateModule } from '@ngx-translate/core';

import { BrowserMessageComponent } from './components/browser-message.component';

@NgModule({
    imports: [
        CommonModule,
        CommonUiModule,
        MessagesModule,
        MessageModule,
        PrimeSharedModule,
        TranslateModule,
    ],
    exports: [
        BrowserMessageComponent,
    ],
    declarations: [
        BrowserMessageComponent,
    ],
    providers: [],
})
export class BrowserDetectionModule { }
