import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, combineLatest, EMPTY, Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { getParser, Parser } from 'bowser';

import {
    BrowserSupportSpecs,
    BrowserDetectionOptions,
    statusPrimeSeverityMap,
    BrowerStatus,
    BrowserDetectionMessage,
    GenericBrowserMessageOptions,
} from '../models';
import { WindowRefService } from '../../core/services/window-ref.service';

/**
 * Browser detection service.
 *
 * After required call to `init()` method, will parse user agent to detect unsupported or broken browsers according to a JSON specs.
 */
@Injectable({ providedIn: 'root' })
export class BrowserDetectionService {
    private warning: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private error: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private specs: BehaviorSubject<BrowserSupportSpecs | null> = new BehaviorSubject(null);

    constructor(
        private httpClient: HttpClient,
        private windowRefService: WindowRefService,
    ) { }

    /**
     * Initialize the Browser detection service. The service will parse user agent from windows object
     * and generate, according to remote specs, a warning and error state accessible through
     * methods of the class.
     *
     * @param initOptions can contain a `store` and `fetchSuccessAction` properties which are
     * references to a NGRX store and action.
     * Providing these options will allow automatic dispatch of browser status in store.
     */
    public init(initOptions: BrowserDetectionOptions = {
        specsUrl: '/assets/browser/specs.json'
    }): void {
        const browser: Parser.Parser = getParser(this.windowRefService.nativeWindow.navigator.userAgent);

        this.httpClient.get<BrowserSupportSpecs>(initOptions.specsUrl)
            .pipe(
                take(1),
                tap((browserSpecs: BrowserSupportSpecs) => {
                    const { error, warning } = browserSpecs;

                    this.specs.next(browserSpecs);
                    this.warning.next(browser.satisfies(warning) || false);
                    this.error.next(browser.satisfies(error) || false);

                    if (!!initOptions.store && !!initOptions.fetchSuccessAction) {
                        initOptions.store.dispatch(initOptions.fetchSuccessAction({
                            warning: this.warning.value,
                            error: this.error.value,
                        }));
                    }
                }),
                catchError((error: any) => {
                    console.error('Error while retrieving browser support specifications from remote server', error);

                    return of(EMPTY);
                }),
            )
            .subscribe();
    }

    /**
     * Sync warning status as boolean for current browser
     */
    public get isBrowserWarningSync(): boolean {
        return this.warning.value;
    }

    /**
     * Sync error status as boolean for current browser
     */
    public get isBrowserErrorSync(): boolean {
        return this.error.value;
    }

    /**
     * Async warning status as observable boolean for current browser
     */
    public get isBrowserWarning(): Observable<boolean> {
        return this.warning.asObservable();
    }

    /**
     * Async error status as observable boolean for current browser
     */
    public get isBrowserError(): Observable<boolean> {
        return this.error.asObservable();
    }

    /**
     * Returns a map containing both `error`, `warning` status boleans
     * along with `specs` that where applied while parsing current browser agent.
     */
    public getBrowserStatus(): Observable<BrowerStatus> {
        return combineLatest([
            this.error,
            this.warning,
            this.specs,
        ]).pipe(
            map(([error, warning, specs]: [boolean, boolean, BrowserSupportSpecs]) => ({
                warning,
                error,
                specs: specs || null,
            })),
        );
    }

    /**
     * Returns a messages map, depending on parsed specifications.
     * Meant to be used in any component to display or alert about browser not being supported/deprecated.
     *
     * @param Object - { closable: boolean } wether message can be closable or not.
     * Meant to be passed down to primeNG message or alert components.
     *
     * @warning messages cannot be customized at this time. If you need custom messages, please subscribe to
     * `getBrowserStatus() and feel free to customize the messages according to retrieved raw status and specs.
     */
    public getGenericMessages({ closable }: GenericBrowserMessageOptions = { closable: true }): Observable<BrowserDetectionMessage[]> {
        return this.getBrowserStatus().pipe(
            map((browserStatus: BrowerStatus): BrowserDetectionMessage[] => {
                const messages: BrowserDetectionMessage[] = [];

                Object.keys(browserStatus).forEach((status: 'error' | 'warning' | 'specs') => {
                    if (status === 'specs') {
                        return;
                    }

                    if (browserStatus[status] && browserStatus.specs?.messages && browserStatus.specs.messages[status]) {
                        messages.push(
                            {
                                severity: statusPrimeSeverityMap[status],
                                detail: browserStatus.specs.messages[status].message.translationKey,
                                closable,
                                data: {
                                    displayLink: !!browserStatus.specs.messages[status].link,
                                    linkText: browserStatus.specs.messages[status].link?.translationKey,
                                    linkUrl: browserStatus.specs.messages[status].link?.url,
                                },
                            },
                        );
                    }
                });

                return messages;
            }),
        );
    }
}
