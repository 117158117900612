import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { tap } from 'rxjs/operators';

import { Actions, ofType, createEffect } from '@ngrx/effects';

import { RouterActions, go, goReload, back, forward } from '../actions/router.actions';
import { WindowRefService } from '../../services/window-ref.service';

@Injectable()
export class RouterEffects {

    public navigate$ = createEffect(() => this.actions$.pipe(
        ofType(go),
        tap(async ({ payload: { path, query: queryParams, extras } }) => {
            await this.router.navigate(path, { queryParams, ...extras });
        }),
    ), { dispatch: false });

    public navigateAndReload$ = createEffect(() => this.actions$.pipe(
        ofType(goReload),
        tap(async ({ payload: { path, query: queryParams, extras } }) => {
            await this.router.navigate(path, { queryParams, ...extras });

            // We hard reload the page using native window object after redirect
            // Useful to clear state but stay on the current page
            this.windowRef.nativeWindow.reload();
        }),
    ), { dispatch: false });

    public navigateBack$ = createEffect(() => this.actions$.pipe(
        ofType(back),
        tap(() => {
            this.location.back();
        }),
    ), { dispatch: false });

    public navigateForward$ = createEffect(() => this.actions$.pipe(
        ofType(forward),
        tap(() => {
            this.location.forward();
        }),
    ), { dispatch: false });

    constructor(
        private actions$: Actions<RouterActions>,
        private router: Router,
        private location: Location,
        private windowRef: WindowRefService,
    ) { }
}
