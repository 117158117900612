export class Traduction {
    constructor(
        public fr: string,
        public en: string
    ) { }

    static fromJSON(json: any): Traduction {
        const object: Traduction = Object.create(Traduction.prototype);
        Object.assign(object, json);
        return object;
    }

    static createLTraductionModel(options?: Partial<Traduction>): Traduction {
        options = options || {};
        return new Traduction(
            options.fr,
            options.en
        );
    }

    toString(): string {
        return `Traduction: ${JSON.stringify(this)}`;
    }
}
