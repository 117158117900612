import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private readonly handledErrors = [
        'ExpressionChangedAfterItHasBeenCheckedError'
    ];

    // On injecte pas les dépendances puisque le ErrorHandler est chargé trop tôt dans le startup cycle.
    // Pour cette raison, seulement l'injector est injecté.
    constructor(private injector: Injector) {
    }

    async handleError(error: any): Promise<void> {
        console.error(error);

        if (!this.isErrorHandled(error)) {
            await this.navigateErrorPage(error);
        }
    }

    // L'erreur est gérée par le GlobalErrorHandler.
    private isErrorHandled(error: any): boolean {
        let msg = '';
        if (typeof error === 'string') {
            msg = error;
        } else if (
            typeof error === 'object' &&
            error &&
            error.message &&
            typeof error.message === 'string'
        ) {
            msg = error.message;
        }

        // tslint:disable-next-line: rxjs-no-unsafe-scope
        return !!this.handledErrors.filter(x => msg.includes(x)).length;
    }

    private async navigateErrorPage(error: any): Promise<void> {
        const ngZone = this.injector.get(NgZone);
        const router = this.injector.get(Router);

        await ngZone.run(async () => {
            error instanceof HttpErrorResponse && error.status > 0 ?
                await router.navigate(['/erreur/http', error.status]) :
                await router.navigate(['/erreur']);
        });
    }
}
