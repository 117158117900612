import { Action, ActionReducer, State } from '@ngrx/store';
import { environment } from '@app/environment';

/**
 * Log all NGRX actions dispatched through application in the console
 *
 * @caution use in non-production environment only
 */
export function logger(
    reducer: ActionReducer<State<any>>
): ActionReducer<State<any>> {
    return (state: State<any>, action: Action) => {
        if (environment.config.logStoreActions) {
            // eslint-disable-next-line no-restricted-syntax
            console.log('[Store] State', state);
            // eslint-disable-next-line no-restricted-syntax
            console.log('[Store] Action', action);
        }

        return reducer(state, action);
    };
}
