import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Tactique, TactiqueAjouterModifierRequest } from '../models/tactique';
import { AppConfiguration } from '../../shared/config/app-config.model';

@Injectable({
    providedIn: 'root',
})
export class TactiqueService {

    private headers = new HttpHeaders({ 'content-type': 'application/json' });

    constructor(private http: HttpClient,
        private appConfig: AppConfiguration) {
    }

    /**
     * Obtenir la liste des tactiques
     */
    private handleError(err: any) {
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `Une erreur est survenue: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            //errorMessage = `Backend code de retour ${err.status}: ${err.body.error}`;
            if (err.error) {
                errorMessage = err.error.message;
            }
        }

        if (!errorMessage) {
            errorMessage = 'Erreur inconnue (Module tactique)....';
        }

        console.error('errorMessage=' + errorMessage);
        return throwError(errorMessage);
    }

    getTactiques(): Observable<Tactique[]> {
        const url = `${this.appConfig.baseBackendUrl}/tactiques`;

        return this.http.get<Tactique[]>(url, { headers: this.headers })
            .pipe(catchError(this.handleError));
    }

    getTactique(id: string): Observable<{}> {
        const url = `${this.appConfig.baseBackendUrl}/tactiques/${id}`;
        return this.http.get<Tactique>(url, { headers: this.headers })
            .pipe(catchError(this.handleError));
    }


    createTactique(tactique: Tactique): Observable<Tactique> {
        const url = `${this.appConfig.baseBackendUrl}/tactiques`;

        const newTactique: TactiqueAjouterModifierRequest = new TactiqueAjouterModifierRequest(tactique.idCampagne, tactique.description,
            tactique.dateDebut, tactique.dateFin, tactique.communication.niveau, tactique.communication.idCategorieCommunication,
            tactique.communication.idCanal, tactique.communication.idLibreService,
            tactique.communication.idGabaritCourriel);

        return this.http.post<Tactique>(url, newTactique, { headers: this.headers })
            .pipe(
                catchError(this.handleError)
            );
    }


    deleteTactique(id: number): Observable<{}> {
        const url = `${this.appConfig.baseBackendUrl}/tactiques/${id}`;

        return this.http.delete<Tactique>(url, { headers: this.headers })
            .pipe(
                catchError(this.handleError)
            );
    }


    associerTactique(id: number): Observable<Tactique> {
        const url = `${this.appConfig.baseBackendUrl}/tactiques/${id}/associer`;  //khaled ??????????
        return this.http.put<Tactique>(url, { headers: this.headers })
            .pipe(
                catchError(this.handleError)
            );
    }


    updateTactique(tactique: Tactique): Observable<Tactique> {
        const url = `${this.appConfig.baseBackendUrl}/tactiques/${tactique.idTactique}`;

        const newTactique: TactiqueAjouterModifierRequest = new TactiqueAjouterModifierRequest(tactique.idCampagne, tactique.description,
            tactique.dateDebut, tactique.dateFin, tactique.communication.niveau, tactique.communication.idCategorieCommunication,
            tactique.communication.idCanal, tactique.communication.idLibreService, tactique.communication.idGabaritCourriel);

        return this.http.put<Tactique>(url, newTactique, { headers: this.headers })
            .pipe(
                // Return the tactique on an update
                map(() => tactique),
                catchError(this.handleError)
            );
    }

}
