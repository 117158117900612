// khaled à valider avec Pierre ou mettre les constantes pour l'application au complet
export enum StatutsCampagne {
    NON_DEBUTE = 'Non débuté',
    EN_COURS = 'En cours',
    TERMINE = 'Terminé'
}

export enum StatutsTactique {
    NON_ASSOCIE = 'Non associé',
    NON_PLANIFIE = 'Non planifié',
    PLANIFIE = 'planifié',
    EN_COURS = 'En cours',
    TERMINE = 'Terminé'
}
export enum StatutsTactiqueSorted {
    NON_ASSOCIE = '1Non associé',
    NON_PLANIFIE = '2Non planifié',
    PLANIFIE = '3planifié',
    EN_COURS = '4En cours',
    TERMINE = '5Terminé'
}

export enum StatutsExecution {
    PLANIFIE = 'Planifié',
    EN_COURS = 'En cours',
    SUSPENDU = 'Suspendu',
    TERMINE = 'Terminé'
}

export enum StatutsExecutionSorted {
    PLANIFIE = '1Planifié',
    EN_COURS = '2En cours',
    SUSPENDU = '3Suspendu',
    TERMINE = '4Terminé'
}
