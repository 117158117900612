import { Params } from '@angular/router';

import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
    Tactique,
    Execution,
    EditMode,
} from '../../../core/models';

import { executionAdapter, ExecutionState } from '../reducers/execution.reducer';
import { getRouterDataEditMode } from './crud-form.selectors';
import { selectAllCanaux, selectAllCategoriesCommunication, selectAllGabaritsCourriel, selectAllLibresServices } from './modele-solicitation.selectors';
import { getRouterParams, getRouterQueryParams } from './router.selectors';
import { selectAllTactiques } from './tactique.selectors';

const { selectAll } = executionAdapter.getSelectors();

export const getExecutionState = createFeatureSelector<ExecutionState>('executions');

export const selectAllExecutions = createSelector(
    getExecutionState,
    selectAll,
);

export const areExecutionsLoading = createSelector(
    getExecutionState,
    (state: ExecutionState) => state && state.loading || false,
);

export const areExecutionsLoaded = createSelector(
    getExecutionState,
    (state: ExecutionState) => state && state.loaded || false,
);

export const getQueryParamsIdTactique = createSelector(
    getRouterQueryParams,
    (queryParams: Params) =>
        queryParams && Number.parseInt(queryParams.idTactique) || null
);

export const getQueryParamsTactiqueIdCampagne = createSelector(
    getQueryParamsIdTactique,
    selectAllTactiques,
    (idTactique: number, tactiques: Tactique[]) => {
        if (idTactique && Array.isArray(tactiques)) {
            const tactique = tactiques.find(t => t.idTactique === idTactique);
            return tactique && tactique.idCampagne || null;
        }
        return null;
    }
);

export const getCurrentExecution = createSelector(
    getRouterParams,
    selectAllExecutions,
    getRouterDataEditMode,
    getQueryParamsIdTactique,
    getQueryParamsTactiqueIdCampagne,
    (params: Params, executions: Execution[], editMode: EditMode, idTactique: number, idCampagne: string) => {
        if (editMode === EditMode.CREATION) {
            return {
                idCampagne: idCampagne,
                idTactique: idTactique,
                dateDebut: null,
                dateFin: null,
                idExecution: null,
                maxCommJourSem: null,
                maxCommJourFDS: null,
                idCalendrierExecution: null,
                suspendu: null,
                statut: null
            } as Execution;
        } else {
            return params
                && params.idExecution // LE :executionId dans la routerModule
                && executions
                && executions.find(execution =>
                    execution.idCalendrierExecution === params.idExecution
                )
                || null;
        }
    }

);

export const getCurrentExecutionWithCampagneId = createSelector(
    getCurrentExecution,
    selectAllTactiques,
    (execution: Execution, tactiques: Tactique[]) => {
        if (!execution || !Array.isArray(tactiques)) {
            return null;
        }

        const tactique = tactiques.find(t => t.idTactique === execution.idTactique);

        return {
            ...execution,
            idCampagne: tactique ? tactique.idCampagne : null
        };
    }
);

export const selectAllTactiquesListeExecution = createSelector(
    selectAllTactiques,
    selectAllCategoriesCommunication,
    selectAllCanaux,
    selectAllLibresServices,
    selectAllGabaritsCourriel,
    (tactiques: Tactique[], categories, canaux, libresServices, gabaritCourriel) => {
        if (tactiques && categories && canaux && libresServices && gabaritCourriel) {
            return tactiques.map((tactique) => {
                return {
                    idTactique: tactique.idTactique, description: tactique.idTactique + '-' + tactique.communication.niveau + '-' +
                        categories.find((cat) => cat.idCategorieCommunication === tactique.communication.idCategorieCommunication).description + '-' +
                        canaux.find((canal) => canal.idCanal === tactique.communication.idCanal).description + '-' +
                        libresServices.find((ls) => ls.idLibreService === tactique.communication.idLibreService).description + '-' +
                        gabaritCourriel.find((gb) => gb.idGabaritCourriel === tactique.communication.idGabaritCourriel).description
                };
            });
        } else { return []; }

    }
);
