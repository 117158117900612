import { createReducer, on, Action } from '@ngrx/store';
import { ModeleSolicitation } from '../../models';

import {
    loadModeleSolicitations,
    loadModeleSolicitationsSuccess,
    loadModeleSolicitationsFail,
} from '../actions/modele-solicitation.actions';

export interface ModeleSolicitationState {
    modeleSolicitations: ModeleSolicitation | null;
    loading: boolean;
    loaded: boolean;
    error: string;
}


export const initialState: ModeleSolicitationState = {
    // additional entity state properties
    modeleSolicitations: null,
    loading: false,
    loaded: false,
    error: null,
};

export const modeleSolicitationsReducer = createReducer<ModeleSolicitationState>(
    initialState,
    on(loadModeleSolicitations, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),

    on(loadModeleSolicitationsSuccess, (state, action): ModeleSolicitationState => {
        return {
            ...state,
            modeleSolicitations: action.modeleSolicitations,
            loading: false,
            loaded: true,
            error: null
        };
    }),

    on(loadModeleSolicitationsFail, (state, action): ModeleSolicitationState => {
        return {
            ...state,
            error: action.error,
            loading: false,
            loaded: false,
        };
    }),

);

export function reducer(state: ModeleSolicitationState | undefined, action: Action) {
    return modeleSolicitationsReducer(state, action);
}
