import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { RouterStateUrl } from '../../models';

export const getRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

/**
 * Get router state from @ngrx/router-store
 */
export const getRouterStateUrl = createSelector(
    getRouterState,
    (routerState: RouterReducerState<RouterStateUrl>) => routerState && routerState.state || {}
);

/**
 * Get current route url
 */
export const getRouterUrl = createSelector(
    getRouterStateUrl,
    (state: RouterStateUrl) => state && state.url
);

/**
 * Get current route url as a string array
 */
export const getRouterUrlArray = createSelector(
    getRouterUrl,
    (url: string) => url && url.split('/').filter(Boolean) || []
);

/**
 * Get all params in current route
 */
export const getRouterParams = createSelector(
    getRouterStateUrl,
    (state: RouterStateUrl) => state && state.params || {}
);

export const getRouterData = createSelector(
    getRouterStateUrl,
    (state: RouterStateUrl) => state && state.data || {}
);

/**
 * Get all query params in current route
 */
export const getRouterQueryParams = createSelector(
    getRouterStateUrl,
    (state: RouterStateUrl) => state && state.queryParams || {}
);
