import { props, createAction, union } from '@ngrx/store';
import { OpenIdClaims, OpenIdUserInfo } from '@ngxhq/security';

export enum UserActionTypes {
    GET_USER_FROM_CLAIMS = '[User] Get User From Claims',
}

/**
 * Retrieve user info from OpenID claims object
 */
export const getUserFromClaims = createAction(
    UserActionTypes.GET_USER_FROM_CLAIMS,
    props<{ userInfo: OpenIdUserInfo, claims: OpenIdClaims }>(),
);

/**
 * NGRX requires that a union type being exported to be used as reference type
 * for methods (like effects) using these actions.
 *
 * This is to ensure proper typing.
 */
const userActions = union({
    getUserFromClaims,
});

export type UserActions = typeof userActions;
