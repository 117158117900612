import { createSelector, createFeatureSelector } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { isEmpty } from 'lodash';
import { ModeleSolicitation } from '../../models';

import { ModeleSolicitationState } from '../reducers/modele-solicitation.reducer';

export const getModeleSolicitationState = createFeatureSelector<ModeleSolicitationState>('modeleSolicitations');

export const areModeleSolicitationLoading = createSelector(
    getModeleSolicitationState,
    (state: ModeleSolicitationState) => state && state.loading || false,
);

export const areModeleSolicitationLoaded = createSelector(
    getModeleSolicitationState,
    (state: ModeleSolicitationState) => state && state.loaded || false,
);

export const getModeleSolicitation = createSelector(
    getModeleSolicitationState,
    (state: ModeleSolicitationState) => state && state.modeleSolicitations || null,
);

export const selectAllCommunications = createSelector(
    getModeleSolicitation,
    (state) => state && state.communications || null,
);

export const selectAllCategoriesCommunication = createSelector(
    getModeleSolicitation,
    (state: ModeleSolicitation) => state && state.categoriesCommunication || [],
);

export const selectAllCanaux = createSelector(
    getModeleSolicitation,
    (state: ModeleSolicitation) => state && state.canaux || [],
);

export const selectAllLibresServices = createSelector(
    getModeleSolicitation,
    (state: ModeleSolicitation) => state && state.libresServices || null,
);

export const selectAllGabaritsCourriel = createSelector(
    getModeleSolicitation,
    (state: ModeleSolicitation) => state && state.gabaritsCourriel || null,
);

export const selectAllGabaritsNotif = createSelector(
    getModeleSolicitation,
    (state: ModeleSolicitation) => state && state.gabaritsNotif || null,
);

export const selectCombinaisons = createSelector(
    getModeleSolicitation,
    (state: ModeleSolicitation) => state && state.combinaisons || null,
);

export const getNiveaux = createSelector(
    selectCombinaisons,
    (combinaisons) => {
        return combinaisons && combinaisons.niveaux || [];
    }
);

export const getChoixAvailableNiveaux = createSelector(
    getNiveaux,
    (niveaux) => {
        if (niveaux && !isEmpty(niveaux)) {
            return  Object.keys(niveaux).map((key) => {
                return { value: key, label: key } as SelectItem;
            }).sort((a: SelectItem, b: SelectItem) => a.label.localeCompare(b.label));
        }
        return [];
    }
);

export const shouldLoadModeleSolicitation = createSelector(
    areModeleSolicitationLoading,
    areModeleSolicitationLoaded,
    (isLoading: boolean, isLoaded: boolean) => {
        if (isLoading) {
            return { shouldLoad: false, isLoaded, isLoading };
        } else {
            if (!isLoaded) {
                return { shouldLoad: true, isLoaded, isLoading };
            }

            return { shouldLoad: false, isLoaded, isLoading };
        }
    }
);
