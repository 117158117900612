import { Communication } from './communication';


export class Tactique {
    constructor(
        public idTactique: number,
        public idCampagne: string,
        public idDatasetPowerBI: string,
        public communication: Communication,
        public description: string,
        public dateDebut: string,
        public dateFin: string,
        public datePremiereExec: string,
        public dateDerniereExec: string,
        public statut: string,
        public idsCalendriersExecution: string[],
        public statutLibelle?: string,
        public statutLibelleSorted?: string,
        public libelleCategorieCommunication?: string,
        public libelleCanal?: string,
        public libelleGabarit?: string,
        public nomDatasetPowerBI?: string
    ) { }

    static fromJSON(json: any): Tactique {
        const object: Tactique = Object.create(Tactique.prototype);
        Object.assign(object, json);
        return object;
    }

    static createTactiqueModel(options?: Partial<Tactique>): Tactique {
        options = options || {};
        return new Tactique(
            options.idTactique,
            options.idCampagne,
            options.idDatasetPowerBI,
            options.communication,
            options.description,
            options.dateDebut,
            options.dateFin,
            options.datePremiereExec,
            options.dateDerniereExec,
            options.statut,
            options.idsCalendriersExecution,
            options.statutLibelle,
            options.nomDatasetPowerBI
        );
    }

    toString(): string {
        return `Tactique: ${JSON.stringify(this)}`;
    }
}

export class TactiqueAjouterModifierRequest {
    constructor(
        public idCampagne: string,
        public description: string,
        public dateDebut: string,
        public dateFin: string,
        public niveau: string,
        public idCategorieCommunication: string,
        public idCanal: string,
        public idLibreService: string,
        public idGabaritCourriel: string,
    ) { }
}
