import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Campagne, CampagneAjouterRequest, CampagneModifierRequest } from '../models/campagne';
import { AppConfiguration } from '../../shared/config/app-config.model';
@Injectable({
  providedIn: 'root',
})
@Injectable()
export class CampagneService {

  /**
   * Obtenir la liste des campagnes
  */
  private headers = new HttpHeaders({ 'content-type': 'application/json' });

  constructor(private http: HttpClient,
    private appConfig: AppConfiguration) {
  }

  private handleError(err: any) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `Une erreur est survenue: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      //errorMessage = `Backend code de retour ${err.status}: ${err.body.error}`;

      if (err.error) {
        errorMessage = err.error.message;
      }
    }

    if (!errorMessage) {
      errorMessage = 'Erreur inconnue (Module campagne)....';

    }
    console.error(err);
    console.error('errorMessage=' + errorMessage);
    return throwError(errorMessage);
  }


  getCampagnes(): Observable<Campagne[]> {
    const url = `${this.appConfig.baseBackendUrl}/campagnes`;

    return this.http.get<Campagne[]>(url, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      );
  }


  getCampagne(id: string): Observable<{}> {
    const url = `${this.appConfig.baseBackendUrl}/campagnes/${id}`;
    return this.http.get<Campagne>(url, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      );
  }


  createCampagne(campagne: Campagne): Observable<Campagne> {
    const url = `${this.appConfig.baseBackendUrl}/campagnes`;

    const newCampagne: CampagneAjouterRequest = new CampagneAjouterRequest(campagne.annee, campagne.dateDebut, campagne.dateFin, campagne.description);
    return this.http.post<Campagne>(url, newCampagne, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      );
  }


  duplicateCampagne(id: string, anneeIncrement: number): Observable<Campagne> {
    const url = `${this.appConfig.baseBackendUrl}/campagnes/${id}/clone`;

    return this.http.post<Campagne>(url, anneeIncrement, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteCampagne(id: string): Observable<{}> {
    const url = `${this.appConfig.baseBackendUrl}/campagnes/${id}`;
    return this.http.delete<Campagne>(url, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  updateCampagne(campagne: Campagne): Observable<Campagne> {
    const url = `${this.appConfig.baseBackendUrl}/campagnes/${campagne.idCampagne}`;

    const newCampagne: CampagneModifierRequest = new CampagneModifierRequest(campagne.dateDebut, campagne.dateFin, campagne.description);

    return this.http.put<Campagne>(url, newCampagne, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      );
  }
}
