import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import moment from 'moment';

import { environment } from '@app/environment';

@Injectable({
    providedIn: 'root'
})
export class I18nService {
    /**
     * Default locale from environment config
     * @private
     */
    private defaultLocale: string = environment.config.defaultLocale;

    /**
     * Available languages from environment config
     * @private
     */
    private availableLanguages: string[] = environment.config.availableLanguages;

    constructor(
        // Third party serivice
        private translateService: TranslateService,
    ) { }

    /**
     * Current language used in the app
     */
    public get currentLang(): string {
        return this.translateService.currentLang;
    }

    /**
     * Initialize i18n logic, compute current and default locales.
     */
    public init(): void {
        this.setDefaultLanguage();
    }

    /**
     * Util method to change language from a lang selector.
     * @param requestedLang the new language to use
     */
    public changeLanguage(requestedLang: string): void {
        if (this.canChangeToGivenLang(requestedLang)) {
            this.translateService.use(requestedLang);

            moment.locale(requestedLang);
        }
    }

    /**
     * Observable Stream containing the lang currenly in usage in the app
     */
    public getCurrentLang(): Observable<string> {
        return this.translateService.onLangChange.pipe(
            startWith({ lang: this.translateService.currentLang, translations: {} }),
            map((event: LangChangeEvent) => {
                return event.lang;
            })
        );
    }

    /**
     * Extract default language from browser locale
     * @private
     */
    private setDefaultLanguage(): void {
        // Set fallback lang
        const defaultLocale = this.defaultLocale;
        const defaultLang = this.extractLangInLocale(defaultLocale);
        this.translateService.setDefaultLang(defaultLang);

        // Detect browser lang and use it if available
        const browserLocale = this.translateService.getBrowserCultureLang();
        const browserLang = this.extractLangInLocale(browserLocale);

        if (this.isLangAvailable(browserLang)) {
            this.translateService.use(browserLang);
            moment.locale(browserLocale);
        } else {
            this.translateService.use(defaultLang);
            moment.locale(defaultLocale);
        }
    }

    /**
     * Extract lang part in locale string
     * @param locale locale string
     * @example extractLangInLocale('fr-CA') === 'fr'
     * @private
     */
    private extractLangInLocale(locale: string): string {
        return locale.split('-')[0].toLowerCase();
    }

    /**
     * Determine if we can change to a given language.
     *
     * If language has an available translation and the requested language is not currently used, then we can change for this language.
     * @param requestedLang e.g. `en` - lang string
     * @private
     */
    private canChangeToGivenLang(requestedLang: string): boolean {
        return this.isLangAvailable(requestedLang) && this.translateService.currentLang !== requestedLang;
    }

    /**
     * Determine if a language is supported (have an available translation file) in the app
     * @param requestedLang e.g. `en` - lang string
     * @private
     */
    private isLangAvailable(requestedLang: string): boolean {
        return this.availableLanguages.includes(requestedLang);
    }
}
