export class Execution {
    public idCampagne: string;

    constructor(
        public idCalendrierExecution: string,
        public idTactique: number,
        public dateDebut: string,
        public dateFin: string,
        public suspendu: boolean,
        public maxCommJourSem: number,
        public maxCommJourFDS: number,
        public statut: string,
        public statutLibelle?: string,
        public statutLibelleSorted?: string,
        public libelleTactique?: string,
        public prioritaire?: boolean,
    ) { }

    static fromJSON(json: any): Execution {
        const object: Execution = Object.create(Execution.prototype);
        Object.assign(object, json);
        return object;
    }

    static createExecutionModel(options?: Partial<Execution>): Execution {
        options = options || {};
        return new Execution(
            options.idCalendrierExecution,
            options.idTactique,
            options.dateDebut,
            options.dateFin,
            options.suspendu,
            options.maxCommJourSem,
            options.maxCommJourFDS,
            options.statut,
        );
    }

    toString(): string {
        return `Execution: ${JSON.stringify(this)}`;
    }
}

export class ExecutionAjouterModifierRequest {
    constructor(
        public idTactique: number,
        public dateDebut: string,
        public dateFin: string,
        public maxCommJourSem: number,
        public maxCommJourFDS: number,
        public prioritaire: boolean
    ) { }
}
