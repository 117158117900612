import {
    Directive,
    Input,
    TemplateRef,
    ViewContainerRef,
    Inject,
} from '@angular/core';

export class LetContext<T> {
    constructor(
        private readonly dir: NgLetDirective<T>,
    ) { }

    public get ngLet(): T {
        return this.dir.ngLet;
    }
}

/**
 * Structural directive to leverage `async` angular pipe to subscribe to observables in the DOM
 *
 * One advantage of this directive, compared to use async pipe in a `*ngIf` or `*ngFor` directive is it
 * will be able to support falsy values. If the observable returns `false`, ngIf would not display DOM element.
 *
 * @example
 * ```
 * <ng-container *ngLet="userPseudo$ | async as pseudo">
 *     <div [class.alert]="!pseudo">
 *          {{ pseudo ? 'Hello ' + pseudo : 'Please choose a custom pseudo' }}
 *      </div>
 * </ng-container>
 * ```
 */

@Directive({
    selector: '[ngLet]',
})
export class NgLetDirective<T> {
    @Input() public ngLet: T;

    constructor(
        @Inject(ViewContainerRef) viewContainer: ViewContainerRef,
        @Inject(TemplateRef) templateRef: TemplateRef<LetContext<T>>
    ) {
        viewContainer.createEmbeddedView(templateRef, new LetContext<T>(this));
    }
}
