import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import {
    loadModeleSolicitations,
    loadModeleSolicitationsFail,
    loadModeleSolicitationsSuccess,
} from '../actions/modele-solicitation.actions';
import { ModeleSolicitationService } from '../../services/modeleSolicitation.service';
import { ModeleSolicitationActions, addOneAppNotification, loadTactiques } from '../actions';
import { ErrorNotification } from '../../models/app-notification.model';

@Injectable()
export class ModeleSolicitationEffects {
    loadModeleSolicitations$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(loadModeleSolicitations),
                mergeMap(() => this.modeleSolicitationService.getModeleSolicitation()
                    .pipe(
                        map(modeleSolicitations => loadModeleSolicitationsSuccess({ modeleSolicitations })),
                        catchError((error: any) => of(loadModeleSolicitationsFail({ error })))
                    )
                )
            );
    });

    loadModeleSolicitationsSuccess$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(loadModeleSolicitationsSuccess),
                switchMap(() => [
                    loadTactiques(),
                ]),
            );
    });

    loadModeleSolicitationsFail$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(loadModeleSolicitationsFail),
                switchMap(({ error }) => [
                    addOneAppNotification({
                        payload: new ErrorNotification({
                            summary: 'common.error',
                            detail: error,
                        }),
                    }),
                ]),
            );
    });

    constructor(
        private actions$: Actions<ModeleSolicitationActions>,
        private modeleSolicitationService: ModeleSolicitationService
    ) { }

}
