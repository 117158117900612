export class Campagne {
    constructor(
        public idCampagne: string,
        public annee: number,
        public sequence: number,
        public description: string,
        public dateDebut: string,
        public dateFin: string,
        public idsTactiques: number[],
        public statut: string,
        public statutLibelle?: string,
        public nbTactiques?: number
    ) { }

    static fromJSON(json: any): Campagne {
        const object: Campagne = Object.create(Campagne.prototype);
        Object.assign(object, json);
        return object;
    }

    static createCampagneModel(options?: Partial<Campagne>): Campagne {
        options = options || {};
        return new Campagne(
            options.idCampagne,
            options.annee,
            options.sequence,
            options.description,
            options.dateDebut,
            options.dateFin,
            options.idsTactiques,
            options.statut
        );
    }

    toString(): string {
        return `Capmpagne: ${JSON.stringify(this)}`;
    }
}
export class CampagneAjouterRequest {
    constructor(
        public annee: number,
        public dateDebut: string,
        public dateFin: string,
        public description: string,
    ) { }
}
export class CampagneModifierRequest {
    constructor(
        public dateDebut: string,
        public dateFin: string,
        public description: string,
    ) { }
}
