import { createReducer, on } from '@ngrx/store';
import { OpenIdClaims, OpenIdUserInfo } from '@ngxhq/security';

import { getUserFromClaims, UserActions } from '../actions/user.actions';

export interface UserState {
    user: Partial<OpenIdClaims & OpenIdUserInfo>;
}

export const userInitialState = {
    user: {},
};

export function userReducer(
    userState: UserState | undefined,
    action: UserActions,
): UserState {
    return createReducer(
        userInitialState,
        on(getUserFromClaims, state => {
            if (!action.claims) {
                return { ...state };
            }

            const user = {
                ...action.claims,
                ...action.userInfo,
            };

            return {
                ...state,
                user,
            };
        }),
    )(userState, action);
}
