// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    name: 'dev',
    production: false,
    securityRating: 1,
    config: {
        logStoreActions: false,
        defaultLocale: 'fr-CA',
        availableLanguages: ['fr', 'en'],
        availableLocales: ['fr-CA', 'en-CA'],
    },
};
