export class LibreService {

    constructor(
        public idLibreService: string,
        public description: string
    ) { }

    static fromJSON(json: any): LibreService {
        const object: LibreService = Object.create(LibreService.prototype);
        Object.assign(object, json);
        return object;
    }

    static createLibreServiceModel(options?: Partial<LibreService>): LibreService {
        options = options || {};
        return new LibreService(
            options.idLibreService,
            options.description
        );
    }

    toString(): string {
        return `LibreService: ${JSON.stringify(this)}`;
    }
}
