import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { of } from 'rxjs';
import { mergeMap, map, catchError, concatMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Update } from '@ngrx/entity';

import {
    ExecutionActions,
    loadExecutions,
    loadExecutionsSuccess,
    loadExecutionsFail,
    updateExecution,
    updateExecutionSuccess,
    updateExecutionFail,
    createExecution,
    createExecutionSuccess,
    createExecutionFail,
    deleteExecution,
    deleteExecutionSuccess,
    deleteExecutionFail,
    arreterExecution,
    arreterExecutionSuccess,
    arreterExecutionFail,
    suspendreExecutionFail,
    reprendreExecutionFail,
    suspendreExecution,
    suspendreExecutionSuccess,
    reprendreExecution,
    reprendreExecutionSuccess,
} from '../actions/execution.actions';
import { go, addOneAppNotification } from '../../../core/store/actions';
import {
    SuccessNotification, ErrorNotification, Execution,
    StatutsExecution, StatutsExecutionSorted
} from '../../../core/models';
import { ExecutionService } from '../../../core/services';
import { selectAllTactiquesListeExecution } from '../selectors';
import { select, Store } from '@ngrx/store';
import { TactiqueState } from '../../../core/store/reducers/tactique.reducer';

@Injectable()
export class ExecutionEffects {

    loadExecutions$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(loadExecutions),
                mergeMap(() => this.executionService.getExecutions()
                    .pipe(
                        withLatestFrom(this.store.pipe(select(selectAllTactiquesListeExecution))),
                        map(([executions, arrayTactique]) =>
                            executions.map(
                                execution => ({
                                    ...execution,
                                    dateDebut: new DatePipe('fr').transform(execution.dateDebut, 'yyyy-MM-dd'),
                                    dateFin: new DatePipe('fr').transform(execution.dateFin, 'yyyy-MM-dd'),
                                    statutLibelle: StatutsExecution[`${execution.statut}`],
                                    statutLibelleSorted: StatutsExecutionSorted[`${execution.statut}`],
                                    libelleTactique: arrayTactique.find((element) => element.idTactique === execution.idTactique).description || '',
                                }))
                        ),
                        map(executions => loadExecutionsSuccess({ executions })),
                        catchError((error: any) => of(loadExecutionsFail({ error })))
                    )
                )
            );
    });


    createExecution$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(createExecution),
                concatMap(action =>
                    this.executionService.createExecution(action.execution)
                        .pipe(
                            withLatestFrom(this.store.pipe(select(selectAllTactiquesListeExecution))),
                            map(([execution, arrayTactique]) => ({
                                ...execution,
                                dateDebut: new DatePipe('fr').transform(execution.dateDebut, 'yyyy-MM-dd'),
                                dateFin: new DatePipe('fr').transform(execution.dateFin, 'yyyy-MM-dd'),
                                statutLibelle: StatutsExecution[`${execution.statut}`],
                                statutLibelleSorted: StatutsExecutionSorted[`${execution.statut}`],
                                libelleTactique: arrayTactique.find((element) => element.idTactique === execution.idTactique).description || '',
                            })),
                            switchMap(execution => [
                                createExecutionSuccess({ execution }),
                                go({ payload: { path: [`/executions`] } })
                            ]
                            ),
                            catchError((error: any) => of(createExecutionFail({ error })))
                        )
                )
            );
    });

    createExecutionSuccess$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(createExecutionSuccess),
                switchMap(() => [
                    addOneAppNotification({
                        payload: new SuccessNotification({
                            summary: 'common.success',
                            detail: 'executions.create.successDetailsMessage',
                        }),
                    }),
                ]),
            );
    });



    updateExecution$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateExecution),
            switchMap((action) => {
                return this.executionService.updateExecution(action.execution).pipe(
                    map((execution) => ({
                        ...execution,
                        dateDebut: new DatePipe('fr').transform(execution.dateDebut, 'yyyy-MM-dd'),
                        dateFin: new DatePipe('fr').transform(execution.dateFin, 'yyyy-MM-dd'),
                        statutLibelle: StatutsExecution[`${execution.statut}`],
                        statutLibelleSorted: StatutsExecutionSorted[`${execution.statut}`],
                    })),
                    switchMap(() => {
                        const updatedExecution: Update<Execution> = {
                            id: action.execution.idCalendrierExecution,
                            changes: {
                                ...action.execution,
                            },
                        };
                        return [updateExecutionSuccess({ execution: updatedExecution })];
                    }),
                    catchError((error: any) => of(updateExecutionFail({ error })))
                );
            })
        );
    });

    updateExecutionSuccess$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(updateExecutionSuccess),
                switchMap(() => [
                    addOneAppNotification({
                        payload: new SuccessNotification({
                            summary: 'common.success',
                            detail: 'executions.update.successDetailsMessage',
                        }),
                    }),
                    go({ payload: { path: [`/executions`] } })
                ]),
            );
    });

    deleteExecution$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(deleteExecution),
                switchMap(action =>
                    this.executionService.deleteExecution(action.currentExecutionId).pipe(
                        map(() => deleteExecutionSuccess({ idExecution: action.currentExecutionId })),
                        catchError((error: any) => of(deleteExecutionFail({ error })))
                    )
                ),
            );
    });

    deleteExecutionSuccess$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(deleteExecutionSuccess),
                switchMap(() => [
                    addOneAppNotification({
                        payload: new SuccessNotification({
                            summary: 'common.success',
                            detail: 'executions.delete.successDetailsMessage',
                        }),
                    }),
                ]),
            );
    });

    arreterExecution$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(arreterExecution),
            switchMap((action) => {
                return this.executionService.arreterExecution(action.currentExecutionId).pipe(
                    map((execution) => ({
                        ...execution,
                        dateDebut: new DatePipe('fr').transform(execution.dateDebut, 'yyyy-MM-dd'),
                        dateFin: new DatePipe('fr').transform(execution.dateFin, 'yyyy-MM-dd'),
                        statutLibelle: StatutsExecution[`${execution.statut}`],
                        statutLibelleSorted: StatutsExecutionSorted[`${execution.statut}`],
                    })),
                    switchMap((execution) => {
                        const arreterUneExecution: Update<Execution> = {
                            id: execution.idCalendrierExecution,
                            changes: {
                                ...execution,
                            },
                        };
                        return [arreterExecutionSuccess({ execution: arreterUneExecution }),
                        ];
                    }),
                    catchError((error: any) => of(arreterExecutionFail({ error })))
                );
            })
        );
    });

    arreterExecutionSuccess$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(arreterExecutionSuccess),
                switchMap(() => [
                    addOneAppNotification({
                        payload: new SuccessNotification({
                            summary: 'common.success',
                            detail: 'executions.arreter.successDetailsMessage',
                        }),
                    }),
                ]),
            );
    });


    suspendreExecution$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(suspendreExecution),
            switchMap((action) => {
                return this.executionService.suspendreExecution(action.currentExecutionId).pipe(
                    map((execution) => ({
                        ...execution,
                        dateDebut: new DatePipe('fr').transform(execution.dateDebut, 'yyyy-MM-dd'),
                        dateFin: new DatePipe('fr').transform(execution.dateFin, 'yyyy-MM-dd'),
                        statutLibelle: StatutsExecution[`${execution.statut}`],
                        statutLibelleSorted: StatutsExecutionSorted[`${execution.statut}`],
                    })),
                    switchMap((execution) => {
                        const suspendreUneExecution: Update<Execution> = {
                            id: execution.idCalendrierExecution,
                            changes: {
                                ...execution,
                            },
                        };
                        return [suspendreExecutionSuccess({ execution: suspendreUneExecution }),
                        ];
                    }),
                    catchError((error: any) => of(suspendreExecutionFail({ error })))
                );
            })
        );
    });

    suspendreExecutionSuccess$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(suspendreExecutionSuccess),
                switchMap(() => [
                    addOneAppNotification({
                        payload: new SuccessNotification({
                            summary: 'common.success',
                            detail: 'executions.suspendre.successDetailsMessage',
                        }),
                    }),
                ]),
            );
    });

    reprendreExecution$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(reprendreExecution),
            switchMap((action) => {
                return this.executionService.reprendreExecution(action.currentExecutionId).pipe(
                    map((execution) => ({
                        ...execution,
                        dateDebut: new DatePipe('fr').transform(execution.dateDebut, 'yyyy-MM-dd'),
                        dateFin: new DatePipe('fr').transform(execution.dateFin, 'yyyy-MM-dd'),
                        statutLibelle: StatutsExecution[`${execution.statut}`],
                        statutLibelleSorted: StatutsExecutionSorted[`${execution.statut}`],
                    })),
                    switchMap((execution) => {
                        const reprendreUneExecution: Update<Execution> = {
                            id: execution.idCalendrierExecution,
                            changes: {
                                ...execution,
                            },
                        };
                        return [reprendreExecutionSuccess({ execution: reprendreUneExecution }),
                        ];
                    }),
                    catchError((error: any) => of(reprendreExecutionFail({ error })))
                );
            })
        );
    });

    reprendreExecutionSuccess$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(reprendreExecutionSuccess),
                switchMap(() => [
                    addOneAppNotification({
                        payload: new SuccessNotification({
                            summary: 'common.success',
                            detail: 'executions.reprendre.successDetailsMessage',
                        }),
                    }),
                ]),
            );
    });


    crudExecutionFail$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(loadExecutionsFail, createExecutionFail, updateExecutionFail, deleteExecutionFail, arreterExecutionFail, suspendreExecutionFail,
                    reprendreExecutionFail),
                switchMap(({ error }) => [
                    addOneAppNotification({
                        payload: new ErrorNotification({
                            summary: 'common.error',
                            detail: error,
                        }),
                    }),
                ]),
            );
    });

    constructor(
        private actions$: Actions<ExecutionActions>,
        private executionService: ExecutionService,
        private store: Store<TactiqueState>,
    ) { }


}
