import { createAction, props, union } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Execution } from '../../../core/models/execution';

export enum ExecutionActionsTypes {
    LOAD_EXECUTIONS = '[PT][execution] Load executions',
    LOAD_EXECUTIONS_SUCCESS = '[PT][execution] Load executions succeeded',
    LOAD_EXECUTIONS_FAIL = '[PT][execution] Load executions failed',
    CREATE_EXECUTION = '[PT][execution] Create execution',
    CREATE_EXECUTION_SUCCESS = '[PT][execution] Create execution succeeded',
    CREATE_EXECUTION_FAIL = '[PT][execution] Create execution failed',
    UPDATE_EXECUTION = '[PT][execution] Update execution',
    UPDATE_EXECUTION_SUCCESS = '[PT][execution] Update execution succeeded',
    UPDATE_EXECUTION_FAIL = '[PT][execution] Update execution failed',
    DELETE_EXECUTION = '[PT][execution] Delete execution',
    DELETE_EXECUTION_SUCCESS = '[PT][execution] Delete execution succeeded',
    DELETE_EXECUTION_FAIL = '[PT][execution] Delete execution failed',
    ARRETER_EXECUTION = '[PT][execution] Arreter execution',
    ARRETER_EXECUTION_SUCCESS = '[PT][execution] Arreter execution succeeded',
    ARRETER_EXECUTION_FAIL = '[PT][execution] Arreter execution failed',
    SUSPENDRE_EXECUTION = '[PT][execution] Suspendre execution',
    SUSPENDRE_EXECUTION_SUCCESS = '[PT][execution] Suspendre execution succeeded',
    SUSPENDRE_EXECUTION_FAIL = '[PT][execution] Suspendre execution failed',
    REPRENDRE_EXECUTION = '[PT][execution] Reprendre execution',
    REPRENDRE_EXECUTION_SUCCESS = '[PT][execution] Reprendre execution succeeded',
    REPRENDRE_EXECUTION_FAIL = '[PT][execution] Reprendre execution failed',
}

export const loadExecutions = createAction(ExecutionActionsTypes.LOAD_EXECUTIONS);
export const loadExecutionsSuccess = createAction(ExecutionActionsTypes.LOAD_EXECUTIONS_SUCCESS, props<{ executions: Execution[] }>());
export const loadExecutionsFail = createAction(ExecutionActionsTypes.LOAD_EXECUTIONS_FAIL, props<{ error: any }>());

export const updateExecution = createAction(ExecutionActionsTypes.UPDATE_EXECUTION, props<{ execution: Execution }>());
export const updateExecutionSuccess = createAction(ExecutionActionsTypes.UPDATE_EXECUTION_SUCCESS, props<{ execution: Update<Execution> }>());
export const updateExecutionFail = createAction(ExecutionActionsTypes.UPDATE_EXECUTION_FAIL, props<{ error: any }>());

export const createExecution = createAction(ExecutionActionsTypes.CREATE_EXECUTION, props<{ execution: Execution }>());
export const createExecutionSuccess = createAction(ExecutionActionsTypes.CREATE_EXECUTION_SUCCESS, props<{ execution: Execution }>());
export const createExecutionFail = createAction(ExecutionActionsTypes.CREATE_EXECUTION_FAIL, props<{ error: any }>());

export const deleteExecution = createAction(ExecutionActionsTypes.DELETE_EXECUTION, props<{ currentExecutionId: string }>());
export const deleteExecutionSuccess = createAction(ExecutionActionsTypes.DELETE_EXECUTION_SUCCESS, props<{ idExecution: string }>());
export const deleteExecutionFail = createAction(ExecutionActionsTypes.DELETE_EXECUTION_FAIL, props<{ error: any }>());

export const arreterExecution = createAction(ExecutionActionsTypes.ARRETER_EXECUTION, props<{ currentExecutionId: string }>());
export const arreterExecutionSuccess = createAction(ExecutionActionsTypes.ARRETER_EXECUTION_SUCCESS, props<{ execution: Update<Execution> }>());
export const arreterExecutionFail = createAction(ExecutionActionsTypes.ARRETER_EXECUTION_FAIL, props<{ error: any }>());

export const suspendreExecution = createAction(ExecutionActionsTypes.SUSPENDRE_EXECUTION, props<{ currentExecutionId: string }>());
export const suspendreExecutionSuccess = createAction(ExecutionActionsTypes.SUSPENDRE_EXECUTION_SUCCESS, props<{ execution: Update<Execution> }>());
export const suspendreExecutionFail = createAction(ExecutionActionsTypes.SUSPENDRE_EXECUTION_FAIL, props<{ error: any }>());

export const reprendreExecution = createAction(ExecutionActionsTypes.REPRENDRE_EXECUTION, props<{ currentExecutionId: string }>());
export const reprendreExecutionSuccess = createAction(ExecutionActionsTypes.REPRENDRE_EXECUTION_SUCCESS, props<{ execution: Update<Execution> }>());
export const reprendreExecutionFail = createAction(ExecutionActionsTypes.REPRENDRE_EXECUTION_FAIL, props<{ error: any }>());

const executionActions = union({
    loadExecutions,
    loadExecutionsSuccess,
    loadExecutionsFail,
    createExecution,
    createExecutionSuccess,
    createExecutionFail,
    updateExecution,
    updateExecutionSuccess,
    updateExecutionFail,
    deleteExecution,
    deleteExecutionSuccess,
    deleteExecutionFail,
    arreterExecution,
    arreterExecutionSuccess,
    arreterExecutionFail,
    suspendreExecution,
    suspendreExecutionSuccess,
    suspendreExecutionFail,
    reprendreExecution,
    reprendreExecutionSuccess,
    reprendreExecutionFail,
});

export type ExecutionActions = typeof executionActions;
