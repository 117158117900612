<hq-header image="assets/theme-ngxhq/images/logo-hydro-quebec-small.png" headerTitle="Pilotage Tactique"
    (expandedChange)='expanded=$event'>
    <hq-header-menu>
        <hq-header-menu-section>
            <hq-header-menu-item-group [icon]="user ? 'appicons ic-user' : 'appicons ic-sign-in-alt'" label="Profile">
                <hq-header-menu-section *ngIf="user">
                    <hq-header-menu-item-container>
                        <div class='hq-header-menu-profile'>
                            <div class='hq-header-menu-profile__image'>
                                <img *ngIf="user?.profileImage" [src]='user?.profileImage' alt='' />
                                <div class='hq-header-menu-profile__initials' *ngIf="!user?.profileImage">
                                    {{user?.initials | uppercase}}
                                </div>
                            </div>
                            <div class='hq-header-menu-profile__description'>
                                <div class='hq-header-menu-profile__user-fullname'>{{user?.fullname}}</div>
                            </div>
                        </div>
                    </hq-header-menu-item-container>
                </hq-header-menu-section>

                <hq-header-menu-section *ngIf="!user">
                    <hq-header-menu-item-container>
                        <button pButton type="button" label="Se connecter" icon='appicons ic-sign-in-alt'
                            [hqClickCommand]="login">
                        </button>
                    </hq-header-menu-item-container>
                </hq-header-menu-section>

                <hq-header-menu-section *ngIf="user">
                    <hq-header-menu-item-container>
                        <button pButton type="button" label="Se déconnecter" icon='appicons ic-sign-out-alt'
                            [hqClickCommand]="logout">
                        </button>
                    </hq-header-menu-item-container>
                </hq-header-menu-section>

            </hq-header-menu-item-group>
        </hq-header-menu-section>
    </hq-header-menu>
</hq-header>
