import { createAction, props, union } from '@ngrx/store';

import { AppNotification } from '../../models/app-notification.model';

export enum AppNotificationActionTypes {
    ADD_ONE = '[App Notification] Show Notification'
}

/**
 * Add one app notification in notification (toast) service queue
 */
export const addOneAppNotification = createAction(
    AppNotificationActionTypes.ADD_ONE,
    props<{ payload: AppNotification }>(),
);

/**
 * NGRX requires that a union type being exported to be used as reference type
 * for methods (like effects) using these actions.
 *
 * This is to ensure proper typing.
 */
const appNotificationsActions = union({
    addOneAppNotification,
});

export type AppNotificationsActions = typeof appNotificationsActions;
