import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { OpenIdAuthGuard } from '@ngxhq/security';

import { LogoutComponent } from './logout/logout.component';
import { GlobalErrorComponent } from './core/errors';
import { MainLoadingResolver } from './core/resolvers/main-loading.resolver';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'campagnes',
        pathMatch: 'full'
    },



    // Pages anonymes
    { path: 'deconnexion', component: LogoutComponent },
    { path: 'erreur', component: GlobalErrorComponent, data: { layout: 'fullscreen' } },
    { path: 'erreur/http/:httpCode', component: GlobalErrorComponent, data: { layout: 'fullscreen' } },
    {
        path: 'campagnes',
        canActivate: [OpenIdAuthGuard],
        loadChildren: () => import('./campagne/campagne.module').then(m => m.CampagneModule),
        resolve: { loading: MainLoadingResolver },
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'tactiques',
        canActivate: [OpenIdAuthGuard],
        loadChildren: () => import('./tactique/tactique.module').then(m => m.TactiqueModule),
        resolve: { loading: MainLoadingResolver },
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'executions',
        canActivate: [OpenIdAuthGuard],
        loadChildren: () => import('./execution/execution.module').then(m => m.ExecutionModule),
        resolve: { loading: MainLoadingResolver },
        runGuardsAndResolvers: 'always'
    },

    // Défaut :
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled'
        })
    ],
    exports: [
        RouterModule,
    ],
    declarations: [],
})
export class AppRoutingModule { }
