export class CategorieCommunication {

    constructor(
        public idCategorieCommunication: string,
        public description: string
    ) { }

    static fromJSON(json: any): CategorieCommunication {
        const object: CategorieCommunication = Object.create(CategorieCommunication.prototype);
        Object.assign(object, json);
        return object;
    }

    static createCategorieCommunicationModel(options?: Partial<CategorieCommunication>): CategorieCommunication {
        options = options || {};
        return new CategorieCommunication(
            options.idCategorieCommunication,
            options.description
        );
    }

    toString(): string {
        return `CategorieCommunication: ${JSON.stringify(this)}`;
    }
}
