import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserState } from '../reducers/user.reducer';

export const getUserState = createFeatureSelector<UserState>('user');

/**
 * Get user information from store as parsed from claims
 */
export const getUserInfos = createSelector(
    getUserState,
    (state: UserState) => state && state.user || {}
);
