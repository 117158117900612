import { Params } from '@angular/router';

import { createSelector, createFeatureSelector } from '@ngrx/store';

import { Campagne, EditMode } from '../../models';
import { campagneAdapter, CampagneState } from '../reducers/campagne.reducer';
import { StatutsCampagne } from '../../models/console-pilotage-constantes';
import { getRouterParams } from './router.selectors';
import { SelectItem } from 'primeng/api/selectitem';
import { getRouterDataEditMode } from './crud-form.selectors';

export const getCampagneState = createFeatureSelector<CampagneState>('campagnes');


// get the selectors
const {
    selectAll,
    selectEntities,
} = campagneAdapter.getSelectors();

// select the dictionary of user entities
export const selectCampagneEntities = createSelector(
    getCampagneState,
    selectEntities,
);

export const selectAllCampagnes = createSelector(
    getCampagneState,
    selectAll,
);

export const getAllCampagnesAsSelectItem = createSelector(
    selectAllCampagnes,
    (campagnes: Campagne[]) => {
        return campagnes.filter(element => element.statutLibelle !== StatutsCampagne.TERMINE)
            .map((campagne) => {
                return { value: campagne.idCampagne, label: campagne.idCampagne } as SelectItem;
            }).sort(function (a: SelectItem, b: SelectItem) {
                return a.label.localeCompare(b.label) * -1;
            });
    }
);

export const areCampagnesLoading = createSelector(
    getCampagneState,
    (state: CampagneState) => {
        return state && state.loading || false;
    }
);

export const areCampagnesLoaded = createSelector(
    getCampagneState,
    (state: CampagneState) => state && state.loaded || false,
);

export const getCurrentCampagne = createSelector(
    getRouterParams,
    selectAllCampagnes,
    getRouterDataEditMode,
    (params: Params, campagnes: Campagne[], editMode: EditMode) => {
        if (editMode === EditMode.CREATION) {
            return {
                annee: null,
                description: null,
                idCampagne: null,
                dateDebut: null,
                dateFin: null,
            } as Campagne;
        } else if (params && params.idCampagne) {
            return campagnes.find(campagne =>
                campagne.idCampagne === params.idCampagne
            ) || null;
        }
        return null;
    }

);


export const shouldLoadCampagnes = createSelector(
    areCampagnesLoading,
    areCampagnesLoaded,
    (isLoading: boolean, isLoaded: boolean) => {
        if (isLoading) {
            return { shouldLoad: false, isLoaded, isLoading };
        } else {
            if (!isLoaded) {
                return { shouldLoad: true, isLoaded, isLoading };
            }

            return { shouldLoad: false, isLoaded, isLoading };
        }
    }
);
