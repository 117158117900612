import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfiguration } from '../../shared/config/app-config.model';
import { ModeleSolicitation } from '../models';

@Injectable({
  providedIn: 'root',
})
@Injectable()
export class ModeleSolicitationService {

  private headers = new HttpHeaders({ 'content-type': 'application/json' });

  constructor(private http: HttpClient,
    private appConfig: AppConfiguration) {
  }

  private handleError(err: any) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `Une erreur est survenue: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      //errorMessage = `Backend code de retour ${err.status}: ${err.body.error}`;
      if (err.error) {
        errorMessage = err.error.message;
      }
    }

    if (!errorMessage) {
      errorMessage = 'Erreur inconnue (Module modèle solicitation)....';
    }

    console.error('errorMessage=' + errorMessage);
    return throwError(errorMessage);
  }


  getModeleSolicitation(): Observable<ModeleSolicitation> {
    const url = `${this.appConfig.baseBackendUrl}/modelesollicitation`;

    return this.http.get<ModeleSolicitation>(url, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      );
  }

}
