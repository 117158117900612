import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
    BusyModule,
    BusyRouterService,
    BusyService,
    CommonUiModule,
    LoadingBehaviorsModule,
    ShellModule,
} from '@ngxhq/common-ui';
import { ConfigModule } from '@ngxhq/config';
import { APP_ENVIRONMENT_CONFIG, OpenIdModule, SecurityModule } from '@ngxhq/security';
import { UtilitiesModule } from '@ngxhq/utilities';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';

/* Store imports */
import { CustomRouterStateSerializer, logger } from './store/utils';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '@app/environment';
import { GlobalErrorComponent, GlobalErrorHandler } from './errors';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { coreReducers } from './store/reducers';
import { coreEffects } from './store/effects';
import { I18nService } from './services/i18n.service';
import { WINDOW } from './tokens/window.token';
/* Get global JS objects */
const getWindow = () => (typeof window !== 'undefined' ? window : null);

/**
 * Store meta reducers setup
 *
 * Meta reducers are some reducers executed on every action dispatch.
 * Logger meta reducer allows logging all actions and payloads in console
 */
export const metaReducers: MetaReducer<any>[] = !environment.production ? [logger] : [];

/**
 * Translate service translations files http loader factory
 *
 * The services uses standard Angular HttpClient to gather the translations from
 * assets json files.
 */
export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        CommonModule,
        UtilitiesModule.forRoot(),
        ConfigModule,
        ConfigModule.forRoot(),
        OpenIdModule.forRoot(),
        SecurityModule.forRoot(),
        CommonUiModule,
        CommonUiModule.forRoot(),
        ShellModule.forRoot(),
        BusyModule,
        BusyModule.forRoot(),
        LoadingBehaviorsModule.forRoot(),

        /* Third parties module imports */
        ToastrModule.forRoot(),

        /* Translate module import */
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient],
            },
        }),

        /* Store modules imports */
        StoreModule.forRoot(coreReducers, { metaReducers, runtimeChecks: { strictActionImmutability: true, strictStateImmutability: true } }),
        StoreRouterConnectingModule.forRoot({
            serializer: CustomRouterStateSerializer,
            stateKey: 'router',
        }),
        EffectsModule.forRoot(coreEffects),

        // Enable Redux Devtools while running non-prod build
        // To get redux devtools extension:
        // https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=en
        environment.production ? [] : StoreDevtoolsModule.instrument({
            name: 'Console Tactique App DevTools',
            maxAge: 25,
            logOnly: true
        }),
    ],
    exports: [
        CommonModule,
        UtilitiesModule,
        ConfigModule,
        SecurityModule,
        CommonUiModule,
    ],
    declarations: [
        GlobalErrorComponent
    ],
    providers: [
        BusyRouterService,
        BusyService,
        I18nService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: APP_ENVIRONMENT_CONFIG,
            useValue: environment
        },

        // Custom router state serializer will be used by NGRX
        // to extract relevant parts from Angular router state
        {
            provide: RouterStateSerializer,
            useClass: CustomRouterStateSerializer,
        },

        // Global window object token injection
        // to be used in WindowRefService as a central access point
        {
            provide: WINDOW,
            useFactory: getWindow,
        },
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
