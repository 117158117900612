import { Component, Input } from '@angular/core';
import { Message } from 'primeng/api';

import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BrowserDetectionService } from '../services/browser-detection.service';

@Component({
    selector: 'app-browser-message',
    templateUrl: 'browser-message.component.html',
    styleUrls: ['./browser-message.component.scss'],
})
export class BrowserMessageComponent {
    @Input() public closable = true;

    public browserMessages$: Observable<Message[]> = this.browserDetectionService
        .getGenericMessages({ closable: this.closable })
        .pipe(
            filter((messages: Message[]) => messages.length > 0),
        );

    constructor(
        public browserDetectionService: BrowserDetectionService,
    ) { }
}
