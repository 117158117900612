import { Traduction } from './traduction';

export class GabaritNotif {

    constructor(
        public idGabaritNotif: string,
        public idTypeNotif: string,
        public descAnonyme: Traduction,
        public descLong: Traduction
    ) { }

    static fromJSON(json: any): GabaritNotif {
        const object: GabaritNotif = Object.create(GabaritNotif.prototype);
        Object.assign(object, json);
        return object;
    }

    static createGabaritNotifModel(options?: Partial<GabaritNotif>): GabaritNotif {
        options = options || {};
        return new GabaritNotif(
            options.idGabaritNotif,
            options.idTypeNotif,
            options.descAnonyme,
            options.descLong
        );
    }

    toString(): string {
        return `GabaritNotif: ${JSON.stringify(this)}`;
    }
}
