import { createReducer, on, Action } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';

import {
    loadTactiques,
    loadTactiquesSuccess,
    loadTactiquesFail,
    createTactique,
    createTactiqueSuccess,
    createTactiqueFail,
    updateTactique,
    updateTactiqueSuccess,
    updateTactiqueFail,
    deleteTactique,
    deleteTactiqueSuccess,
    deleteTactiqueFail,
    associerTactique,
    associerTactiqueSuccess,
    associerTactiqueFail,
} from '../actions/tactique.actions';
import { Tactique } from '../../models/tactique';
import {
    deleteCampagneSuccess,
    duplicateCampagneSuccess,
    createExecutionSuccess,
    deleteExecutionSuccess
} from '../actions';

export interface TactiqueState extends EntityState<Tactique> {
    loading: boolean;
    loaded: boolean;
    error: string;
}


export function selectTactiqueId(a: Tactique): number {
    return a.idTactique;
}

export const tactiqueAdapter: EntityAdapter<Tactique> = createEntityAdapter<Tactique>({
    selectId: selectTactiqueId,
    sortComparer: false,
});

export const initialState: TactiqueState = tactiqueAdapter.getInitialState({
    // additional entity state properties
    loading: false,
    loaded: false,
    error: null,
});

export const tactiquesReducer = createReducer<TactiqueState>(
    initialState,
    on(loadTactiques, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(loadTactiquesSuccess, (state, { tactiques }) => {
        return tactiqueAdapter.setAll(tactiques, {
            ...state,
            loading: false,
            loaded: true,
            error: null,
        });
    }),
    on(loadTactiquesFail, (state, action): TactiqueState => {
        return {
            ...state,
            error: action.error,
            loading: false,
            loaded: false,
        };
    }),

    on(deleteTactique, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(deleteTactiqueSuccess, (state, { idTactique }) => {
        return tactiqueAdapter.removeOne(idTactique, {
            ...state,
            loading: false,
            error: null,
        });
    }),
    on(deleteTactiqueFail, (state, action): TactiqueState => {
        return {
            ...state,
            error: action.error,
            loading: false,
        };
    }),

    on(deleteExecutionSuccess, (state) => {
        return {
            ...state,
            loaded: false,
        };
    }),

    on(createExecutionSuccess, (state) => {
        return {
            ...state,
            loaded: false,
        };
    }),

    on(createTactique, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(createTactiqueSuccess, (state, { tactique }) => {
        return tactiqueAdapter.addOne(tactique, {
            ...state,
            loading: false,
            error: null,
        });
    }),
    on(createTactiqueFail, (state, action): TactiqueState => {
        return {
            ...state,
            error: action.error,
            loading: false,
        };
    }),

    on(updateTactique, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(updateTactiqueSuccess, (state, { tactique }) => {

        return tactiqueAdapter.updateOne(tactique,
            {
                ...state,
                loading: false,
                error: null,
            }
        );
    }),
    on(updateTactiqueFail, (state, action): TactiqueState => {
        return {
            ...state,
            error: action.error,
            loading: false,
        };
    }),
    on(associerTactique, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(associerTactiqueSuccess, (state, { tactique }) => {

        return tactiqueAdapter.updateOne(tactique,
            {
                ...state,
                loading: false,
                error: null,
            }
        );
    }),
    on(associerTactiqueFail, (state, action): TactiqueState => {
        return {
            ...state,
            error: action.error,
            loading: false,
        };
    }),
    on(
        duplicateCampagneSuccess,
        (state) => {
            return {
                ...state,
                loaded: false,
            };
        }),
    on(deleteCampagneSuccess, (state, { idCampagne }) => {
        const keys = Object.values(state.entities)
            .filter(tactique => tactique.idCampagne === idCampagne)
            .map((tactique) => tactique.idTactique);
        return tactiqueAdapter.removeMany(keys, state);
    })
);

export function reducer(state: TactiqueState | undefined, action: Action) {
    return tactiquesReducer(state, action);
}
