import { Injectable } from '@angular/core';

import { tap, withLatestFrom } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { UserActions, getUserFromClaims } from '../actions/user.actions';
import { WindowRefService } from '../../services/window-ref.service';
import { getUserInfos } from '../selectors';

@Injectable()
export class UserEffects {
    public updateUserLocalStorage$ = createEffect(() => this.actions$.pipe(
        ofType(getUserFromClaims),
        withLatestFrom(this.store.pipe(select(getUserInfos))),
        tap(([_, user]) => {
            this.windowRef.nativeWindow.localStorage.setItem('userId', user.id);
            this.windowRef.nativeWindow.localStorage.setItem('userEmail', user.email);
        }),
    ), { dispatch: false });

    constructor(
        private readonly store: Store,
        private actions$: Actions<UserActions>,
        private windowRef: WindowRefService,
    ) { }
}
