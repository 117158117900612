import { createAction, props, union } from '@ngrx/store';

export enum CrudFormActionsTypes {
    FORM_UPDATE = '[CRUD form] Update Form Value',
    FORM_RESET = '[CRUD form] Reset',
}

export const updateForm = createAction(CrudFormActionsTypes.FORM_UPDATE, props<{ formId: string, value: any }>());
export const resetForm = createAction(CrudFormActionsTypes.FORM_RESET, props<{ formId: string }>());

const crudFormActions = union({
    updateForm,
    resetForm,
});

export type CrudFormActions = typeof crudFormActions;
