import _ from 'lodash';

export class ManageHelpers {
    /**
     * Removes nil fields in objet recursively
     *
     * @param obj The object you want to clean
     * @example
     * ```
     * const obj = { foo: { bar: 'Baz', stephen: null, empty: {} } };
     * deepRemoveNilFields(obj) // '{ foo: { bar: 'Baz'}}'
     * ```
     */
    public static deepRemoveNilFields = (obj: any): any => {
        return _(obj)
            .pickBy(_.isPlainObject) // get only objects
            .mapValues(ManageHelpers.deepRemoveNilFields) // call only for values as objects
            .assign(_.omitBy(obj, _.isPlainObject)) // save back result that is not object
            .omitBy(_.isNil) // remove null and undefined from object
            .omitBy((o) => typeof o === 'string' ? Object.values(o).length === 0
                : (typeof o === 'number' ? o < 0
                    : (typeof o === 'object' ? Object.entries(o).length === 0 && o.constructor === Object : false)))
            .value(); // get value
    };

    /**
     * checks whether an object is empty or not
     *
     * @param object object to extract values from
     * @returns boolean
     */
    public static isEmptyObject = (obj: any): boolean => {
        if (typeof obj === 'object' && Object.prototype.toString.call(obj) === '[object Object]') {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    return false;
                }
            }

            return true;
        }

        return false;
    };

    /**
     * Remove duplicate entries in an array by matching a given predicate.
     *
     * @example
     * ```
     * removeDuplicates((item: T) => item.id),[ { id: 1 }, { id: 2 }, { id: 1 }])
     *   === [{ id: 1 }, { id: 2 }]
     * ```
     */
    public static removeDuplicates = <T>(predicate: (item: T) => any, arr: T[]): T[] => {
        const set: Set<any> = new Set();

        return arr.filter((item: T) => {
            const key = predicate(item);
            const isNew = !key || !set.has(key);

            if (isNew) {
                set.add(key);
            }

            return isNew;
        });
    };

    /**
     * Generate a v4 UUID
     * Used only in app notifications at the moment.
     *
     * @caution If relying on trustworthy UUID, please use a third party
     */
    /* tslint:disable:no-bitwise */
    /* tslint:disable:only-arrow-functions */
    /* tslint:disable:one-variable-per-declaration */
    public static uuidv4 = (): string => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c): string {
            const r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    };
}
