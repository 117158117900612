
import { Canal } from './canal';
import { LibreService } from './libreService';
import { CategorieCommunication } from './categorieCommunication';
import { GabaritCourriel } from './gabaritCourriel';
import { GabaritNotif } from './gabaritNotif';
import { Combinaisons } from './combinaisons';
import { Communication } from './communication';

export class ModeleSolicitation {

    constructor(
        public communications: Communication[],
        public niveaux: string[],
        public categoriesCommunication: CategorieCommunication[],
        public canaux: Canal[],
        public libresServices: LibreService[],
        public gabaritsCourriel: GabaritCourriel[],
        public gabaritsNotif: GabaritNotif[],
        public combinaisons: Combinaisons,
    ) { }

    static fromJSON(json: any): ModeleSolicitation {
        const object: ModeleSolicitation = Object.create(ModeleSolicitation.prototype);
        Object.assign(object, json);
        return object;
    }

    static createModeleSolicitationModel(options?: Partial<ModeleSolicitation>): ModeleSolicitation {
        options = options || {};
        return new ModeleSolicitation(
            options.communications,
            options.niveaux,
            options.categoriesCommunication,
            options.canaux,
            options.libresServices,
            options.gabaritsCourriel,
            options.gabaritsNotif,
            options.combinaisons
        );
    }

    toString(): string {
        return `ModeleSolicitation: ${JSON.stringify(this)}`;
    }
}
