import { createAction, props, union } from '@ngrx/store';
import { ModeleSolicitation } from '../../models';

export enum ModeleSolicitationActionsTypes {
    LOAD_MODELE_SOLICITATIONS = '[PT][tactique] Load modele solicatations',
    LOAD_MODELE_SOLICITATIONS_SUCCESS = '[PT][tactique] Load modele solicitations succeeded',
    LOAD_MODELE_SOLICITATIONS_FAIL = '[PT][tactique] Load modele solicitations failed',
}

export const loadModeleSolicitations = createAction(ModeleSolicitationActionsTypes.LOAD_MODELE_SOLICITATIONS);
export const loadModeleSolicitationsSuccess = createAction(ModeleSolicitationActionsTypes.LOAD_MODELE_SOLICITATIONS_SUCCESS,
    props<{ modeleSolicitations: ModeleSolicitation }>());
export const loadModeleSolicitationsFail = createAction(ModeleSolicitationActionsTypes.LOAD_MODELE_SOLICITATIONS_FAIL,
    props<{ error: any }>());


const modeleSolicitationActions = union({
    loadModeleSolicitations,
    loadModeleSolicitationsSuccess,
    loadModeleSolicitationsFail,
});

export type ModeleSolicitationActions = typeof modeleSolicitationActions;
