import { createAction, props, union } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Campagne } from '../../models/campagne';

export enum CampagneActionsTypes {
    SET_CAMPAGNES = '[PT][campagne] Set campagne ids',
    LOAD_CAMPAGNES = '[PT][campagne] Load campagnes',
    LOAD_CAMPAGNES_SUCCESS = '[PT][campagne] Load campagnes succeeded',
    LOAD_CAMPAGNES_FAIL = '[PT][campagne] Load campagnes failed',
    SET_CURRENT_CAMPAGNE_ID = '[PT][campagne] Set current campagne id',
    CLEAR_CURRENT_CAMPAGNE = '[PT][campagne] Clear current campagne',
    CREATE_CAMPAGNE = '[PT][campagne] Create campagne',
    CREATE_CAMPAGNE_SUCCESS = '[PT][campagne] Create campagne succeeded',
    CREATE_CAMPAGNE_FAIL = '[PT][campagne] Create campagne failed',
    UPDATE_CAMPAGNE = '[PT][campagne] Update campagne',
    UPDATE_CAMPAGNE_SUCCESS = '[PT][campagne] Update campagne succeeded',
    UPDATE_CAMPAGNE_FAIL = '[PT][campagne] Update campagne failed',
    DELETE_CAMPAGNE = '[PT][campagne] Delete campagne',
    DELETE_CAMPAGNE_SUCCESS = '[PT][campagne] Delete campagne succeeded',
    DELETE_CAMPAGNE_FAIL = '[PT][campagne] Delete campagne failed',
    DUPLICATE_CAMPAGNE = '[PT][campagne] Duplicate campagne',
    DUPLICATE_CAMPAGNE_SUCCESS = '[PT][campagne] Duplicate campagne succeeded',
    DUPLICATE_CAMPAGNE_FAIL = '[PT][campagne] Duplicate campagne failed',
    SET_SOULD_LOAD_CAMPAGNES = '[PT][campagne] Set Shoud Load Campagnes',
}


export const setCurrentCampagneId = createAction(CampagneActionsTypes.SET_CURRENT_CAMPAGNE_ID, props<{ currentCampagneId: string }>());
export const clearCurrentCampagne = createAction(CampagneActionsTypes.CLEAR_CURRENT_CAMPAGNE);

export const loadCampagnes = createAction(CampagneActionsTypes.LOAD_CAMPAGNES);

export const setCampagnes = createAction(CampagneActionsTypes.SET_CAMPAGNES, props<{ campagnes: Campagne[] }>());
export const loadCampagnesSuccess = createAction(CampagneActionsTypes.LOAD_CAMPAGNES_SUCCESS, props<{ campagnes: Campagne[] }>());
export const loadCampagnesFail = createAction(CampagneActionsTypes.LOAD_CAMPAGNES_FAIL, props<{ error: any }>());

export const updateCampagne = createAction(CampagneActionsTypes.UPDATE_CAMPAGNE, props<{ campagne: Campagne }>());
export const updateCampagneSuccess = createAction(CampagneActionsTypes.UPDATE_CAMPAGNE_SUCCESS, props<{ campagne: Update<Campagne> }>());
export const updateCampagneFail = createAction(CampagneActionsTypes.UPDATE_CAMPAGNE_FAIL, props<{ error: any }>());

export const createCampagne = createAction(CampagneActionsTypes.CREATE_CAMPAGNE, props<{ campagne: Campagne }>());
export const createCampagneSuccess = createAction(CampagneActionsTypes.CREATE_CAMPAGNE_SUCCESS, props<{ campagne: Campagne }>());
export const createCampagneFail = createAction(CampagneActionsTypes.CREATE_CAMPAGNE_FAIL, props<{ error: any }>());

export const deleteCampagne = createAction(CampagneActionsTypes.DELETE_CAMPAGNE, props<{ currentCampagneId: string }>());
export const deleteCampagneSuccess = createAction(CampagneActionsTypes.DELETE_CAMPAGNE_SUCCESS, props<{ idCampagne: string }>());
export const deleteCampagneFail = createAction(CampagneActionsTypes.DELETE_CAMPAGNE_FAIL, props<{ error: any }>());

export const duplicateCampagne = createAction(CampagneActionsTypes.DUPLICATE_CAMPAGNE, props<{ currentCampagneId: string, anneeIcrement: number }>());
export const duplicateCampagneSuccess = createAction(CampagneActionsTypes.DUPLICATE_CAMPAGNE_SUCCESS, props<{ campagne: Campagne }>());
export const duplicateCampagneFail = createAction(CampagneActionsTypes.DUPLICATE_CAMPAGNE_FAIL, props<{ error: any }>());

export const setShouldLoadCampagnes = createAction(CampagneActionsTypes.SET_SOULD_LOAD_CAMPAGNES);

const campagneActions = union({
    setCampagnes,
    loadCampagnes,
    loadCampagnesSuccess,
    loadCampagnesFail,
    setCurrentCampagneId,
    clearCurrentCampagne,
    createCampagne,
    createCampagneSuccess,
    createCampagneFail,
    updateCampagne,
    updateCampagneSuccess,
    updateCampagneFail,
    deleteCampagne,
    deleteCampagneSuccess,
    deleteCampagneFail,
    duplicateCampagne,
    duplicateCampagneSuccess,
    duplicateCampagneFail,
    setShouldLoadCampagnes,
});

export type CampagneActions = typeof campagneActions;
