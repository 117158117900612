import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Component used to display a loader inside a relative HTML element.
 *
 * The loader will be scoped in the parent element.
 */
@Component({
    selector: 'app-element-loader',
    templateUrl: './element-loader.component.html',
    styleUrls: ['./element-loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElementLoaderComponent { }
