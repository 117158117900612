<ng-container *ngIf="browserMessages$ | async as messages">
    <p-messages *ngFor="let message of messages" [severity]="message.severity" [enableService]="false">
        <ng-template pTemplate>
            <span class="p-message-icon pi pi-exclamation-triangle" *ngIf="message.severity === 'warn'"></span>
            <span class="p-message-icon pi pi-times-circle" *ngIf="message.severity === 'error'"></span>

            <div class="p-message-detail">
                <p>{{ message.detail | translate }}</p>
                <p *ngIf="message.data?.displayLink">
                    <a [href]="message.data.linkUrl">{{ message.data.linkText | translate }}</a>
                </p>
            </div>
        </ng-template>
    </p-messages>
</ng-container>
