export interface CombinaisonLibreService {
    [idLibreService: string]: {
        gabaritsCourriel: string[];
    };
}

export interface CombinaisonCanal {
    [idCanal: string]: {
        libresServices: CombinaisonLibreService;
    };
}

export interface CombinaisonCategorieCommunication {
    [idCategorieCommunication: string]: {
        canaux: CombinaisonCanal;
    };
}

export interface CombinaisonNiveau {
    [niveau: string]: {
        categoriesCommunication: CombinaisonCategorieCommunication;
    };
}

export class Combinaisons {
    constructor(public niveaux: CombinaisonNiveau) {
    }
}
