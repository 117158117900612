import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer } from '@ngrx/store';

import { AppNotificationsActions } from '../actions/app-notifications.actions';
import { AppNotification } from '../../models/app-notification.model';

export interface AppNotificationsState extends EntityState<AppNotification> { }
export const appNotificationsAdapter = createEntityAdapter<AppNotification>();

const initialState: AppNotificationsState = appNotificationsAdapter.getInitialState();

export function appNotificationsReducer(
    state: AppNotificationsState | undefined,
    action: AppNotificationsActions,
): AppNotificationsState {
    return createReducer(
        initialState,
    )(state, action);
}
