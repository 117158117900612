import { createAction, props, union } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Tactique } from '../../models/tactique';

export enum TactiqueActionsTypes {
    LOAD_TACTIQUES = '[PT][tactique] Load tactiques',
    LOAD_TACTIQUES_SUCCESS = '[PT][tactique] Load tactiques succeeded',
    LOAD_TACTIQUES_FAIL = '[PT][tactique] Load tactiques failed',
    CREATE_TACTIQUE = '[PT][tactique] Create tactique',
    CREATE_TACTIQUE_SUCCESS = '[PT][tactique] Create tactique succeeded',
    CREATE_TACTIQUE_FAIL = '[PT][tactique] Create tactique failed',
    UPDATE_TACTIQUE = '[PT][tactique] Update tactique',
    UPDATE_TACTIQUE_SUCCESS = '[PT][tactique] Update tactique succeeded',
    UPDATE_TACTIQUE_FAIL = '[PT][tactique] Update tactique failed',
    DELETE_TACTIQUE = '[PT][tactique] Delete tactique',
    DELETE_TACTIQUE_SUCCESS = '[PT][tactique] Delete tactique succeeded',
    DELETE_TACTIQUE_FAIL = '[PT][tactique] Delete tactique failed',
    SET_SOULD_LOAD_TACTIQUES = '[PT][tactique] Set Shoud Load Tactiques',
    ASSOCIER_TACTIQUE = '[PT][tactique] Associer tactique',
    ASSOCIER_TACTIQUE_SUCCESS = '[PT][tactique] Associer tactique succeeded',
    ASSOCIER_TACTIQUE_FAIL = '[PT][tactique] Associer tactique failed',
    SAVE_TACTIQUE = '[PT][tactique] Crée ou modifie une tactique',
}

export const loadTactiques = createAction(TactiqueActionsTypes.LOAD_TACTIQUES);
export const loadTactiquesSuccess = createAction(TactiqueActionsTypes.LOAD_TACTIQUES_SUCCESS, props<{ tactiques: Tactique[] }>());
export const loadTactiquesFail = createAction(TactiqueActionsTypes.LOAD_TACTIQUES_FAIL, props<{ error: any }>());

export const updateTactique = createAction(TactiqueActionsTypes.UPDATE_TACTIQUE, props<{ tactique: Tactique }>());
export const updateTactiqueSuccess = createAction(TactiqueActionsTypes.UPDATE_TACTIQUE_SUCCESS, props<{ tactique: Update<Tactique> }>());
export const updateTactiqueFail = createAction(TactiqueActionsTypes.UPDATE_TACTIQUE_FAIL, props<{ error: any }>());

export const createTactique = createAction(TactiqueActionsTypes.CREATE_TACTIQUE, props<{ tactique: Tactique }>());
export const createTactiqueSuccess = createAction(TactiqueActionsTypes.CREATE_TACTIQUE_SUCCESS, props<{ tactique: Tactique }>());
export const createTactiqueFail = createAction(TactiqueActionsTypes.CREATE_TACTIQUE_FAIL, props<{ error: any }>());

export const deleteTactique = createAction(TactiqueActionsTypes.DELETE_TACTIQUE, props<{ currentTactiqueId: number }>());
export const deleteTactiqueSuccess = createAction(TactiqueActionsTypes.DELETE_TACTIQUE_SUCCESS, props<{ idTactique: number }>());
export const deleteTactiqueFail = createAction(TactiqueActionsTypes.DELETE_TACTIQUE_FAIL, props<{ error: any }>());

export const associerTactique = createAction(TactiqueActionsTypes.ASSOCIER_TACTIQUE, props<{ currentTactiqueId: number }>());
export const associerTactiqueSuccess = createAction(TactiqueActionsTypes.ASSOCIER_TACTIQUE_SUCCESS, props<{ tactique: Update<Tactique> }>());
export const associerTactiqueFail = createAction(TactiqueActionsTypes.ASSOCIER_TACTIQUE_FAIL, props<{ error: any }>());

const tactiqueActions = union({
    loadTactiques,
    loadTactiquesSuccess,
    loadTactiquesFail,
    createTactique,
    createTactiqueSuccess,
    createTactiqueFail,
    updateTactique,
    updateTactiqueSuccess,
    updateTactiqueFail,
    deleteTactique,
    deleteTactiqueSuccess,
    deleteTactiqueFail,
    associerTactique,
    associerTactiqueSuccess,
    associerTactiqueFail
});

export type TactiqueActions = typeof tactiqueActions;
